import React, {useEffect, useRef, useState} from "react"
import {
    Button,
    Div, File, FormItem, Group, Header, SplitLayout,
    Panel, PanelHeader, Progress, ScreenSpinner, Search
} from "@vkontakte/vkui";
import {useGlobalState} from "../../../components/States";
import Api from "../../../components/Api";
import MySnack from "../../../alerts/MySnack";
import Error from "../../../alerts/Error";
import AddCategory from "../../../alerts/AddCategory";
import {Icon24AddCircleOutline, Icon24Document} from "@vkontakte/icons";
import PlaceCard from "../../../components/Place/PlaceCard";
import api from "../../../components/Api";
import {prependToArr} from "../../../components/CONSTS";
import Confirm from "../../../alerts/Confirm";

const Places = ({id, selectPlace, go}) => {
    const [popout, setPopout] = useGlobalState("popout");
    const [snack, setSnack] = useState(null)
    const [search,setSearch] = useState("")
    const [places, setPlaces] = useGlobalState("places");
    const [progressExcel, setProgressExcel] = useState(null)
    const inpRef = useRef();

    const createEvent = (name) => {
        setPopout(<ScreenSpinner size={"large"}/>)
        Api.createPlace(name).then(r => {
            if (!r.result) {
                alert(r.mess, true)
            } else {
                let cats = [...places];
                cats = prependToArr({title:name,enable:false, id: parseInt(r.id)},cats);
                setPlaces([...cats])
                alert("Место создано")
            }
        }).catch((r) => {
            alert(r.message, true)
        });
    }
    const alert = (mess, err = false) => {
        if (!err) {
            setSnack(<MySnack mess={mess} setSnackbar={setSnack}/>);
        } else {
            setPopout(<Error closePopout={() => setPopout(null)} mess={mess}/>)
        }
    }
    const addCat = () => {
        setPopout(<AddCategory inputRef={inpRef} closePopout={() => setPopout(null)} fn={createEvent}/>)
    }
    const enableEvent = (id) => {
        Api.enablePlace(id).then(r => {
            if (!r.result) {
                alert(r.mess, true)
            } else {
                let cats = [...places];
                let index = cats.findIndex(x => parseInt(x.id) === id)
                cats[index].enable = !cats[index].enable;
                setPlaces([...cats])
                alert(`Место ${cats[index].enable ? "включено" : "выключено"}`)
            }
        })
    }
    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgressExcel(percent.toFixed(0))
    }
    const addDocs = docs =>{
        let cats = [...docs,...places];
        setPlaces([...cats])
    }
    const uploadFile = (e) => {
        let file = e.currentTarget.files
        if (!file || file.length === 0)
            return;
        setPopout(<ScreenSpinner state="loading"/>);
        api.uploadExcel("places", file[0], onUploadProgress).then(r => {
            if (!r.result) {
                setPopout(null)
                setSnack(<MySnack mess={"Ошибка. Перезагрузите страницу, может это ложная ошибка "} error={true} setSnackbar={setSnack}/>)
            } else {
                addDocs(r.data)
                setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                setProgressExcel(null)
                setTimeout(clearPopout,1000);
            }
        })
    }
    const clearPopout = () =>setPopout(null);
    const activateAll = act =>{
        Api.enableAllPlace(act).then(r => {
            if (!r.result) {
                alert(r.mess, true)
            } else {
                let cats = [...places];
                cats.map(x=>x.enable = act);
                setPlaces([...cats])
                alert(`Места ${act ? "включены" : "выключены"}`)
            }
        })
    }
    const filter = arr =>{
        if(!search || search.length < 3)
            return arr;
        else {
            return arr.filter(x=>x.title.toLowerCase().includes(search.toLowerCase()))
        }
    }
    const yesDeleteCard = id =>{
        setPopout(null)
        Api.removePlace(id)
        let places_ = [...places];
        let index = places_.findIndex(x=>x.id === id)
        places_.splice(index,1);
        setPlaces([...places_])
    }
    const deleteCard = id =>{
        setPopout(<Confirm mess={"Уверены, что хотите удалить это мероприятие?"} closePopout={()=>setPopout(null)} fn={()=>yesDeleteCard(id)}/>)
    }
    return (
        <Panel id={id}>
            <PanelHeader>
                Места
            </PanelHeader>
            <Div className={"p-div"}>
                <Group header={
                    <div className="div-header">
                        <Header mode={"secondary"}>Список</Header>
                        <div className={"div-row"}>
                            <Button onClick={()=>activateAll(true)} appearance={"positive"}>
                                Активировать все
                            </Button>
                            <Button onClick={()=>activateAll(false)} appearance={"negative"}>
                                Деактивировать все
                            </Button>
                            <FormItem top="Автозагрузка">
                                <File accept=".xl*" onChange={uploadFile} before={<Icon24Document role="presentation"/>}
                                      size="l" mode="secondary"/>
                                {progressExcel && progressExcel >= 0 &&
                                    <Progress value={progressExcel}/>
                                }
                            </FormItem>
                            <Button onClick={addCat} before={<Icon24AddCircleOutline/>} mode={"primary"}
                                    appearance={"positive"}>
                                Добавить место
                            </Button>
                        </div>
                    </div>

                }>
                    <Search style={{width:300}} value={search} onChange={(e)=>setSearch(e.currentTarget.value)} />
                    <div className="group-row">
                        {places && filter(places).map((x, k) => (
                            <PlaceCard
                                deletePlace={()=>deleteCard(x.id)}
                                onClick={() => selectPlace(x)}
                                enableBox={true}
                                enable={x.enable}
                                key={k}
                                enableMe={() => enableEvent(parseInt(x.id))}
                                description={x.sub_title}
                                title={x.title}
                                img={x.photos && x.photos.length ? x.photos[0].path : ""}
                            />
                        ))}
                    </div>

                </Group>
            </Div>
            {snack}
        </Panel>
    )
}
export default Places