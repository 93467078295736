import React, {useState} from "react"
import {
    Alert,Input,FormItem
} from "@vkontakte/vkui"

const Error = ({mess,closePopout}) =>{

    return (
        <Alert
            actions={[
                {
                    title: 'Ok',
                    autoClose: true,
                }
            ]}
            actionsLayout="horizontal"
            onClose={closePopout}
            header="Ошибка"
            text={mess}
        />
    )
}
export default Error