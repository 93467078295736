import React, {useEffect, useRef, useState} from "react"
import {
    Alert,Progress,FormItem
} from "@vkontakte/vkui"
import {SITE_URL} from "../components/CONSTS";
import axios from "axios";
import api from "../components/Api";

const FilesUpload = ({mess,files,fn,closePopout,project_id,project_type}) =>{
    const [completedCount,setCompltedCount] = useState(0)
    const [percent,setPercent] = useState(0)
    const compRef = useRef();
    compRef.current = completedCount;
    const [images,setImages] = useState([])
    const imagesRef = useRef()
    imagesRef.current = images;
    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setPercent(percent.toFixed(0))
    }
    const complete =async () => {
        setCompltedCount(compRef.current + 1);
        setPercent(0)
    };

    useEffect(()=>{
        if(files){
            api.uploadImages(project_type,project_id,files,onUploadProgress,complete).then((r)=>{
                fn(r)
            })
        }
    },[])
    return (
        <Alert
            actions={[

            ]}
            actionsLayout="horizontal"
            onClose={closePopout}
        >
            <FormItem id="progresslabel" top={`Загружаем [${completedCount}/${files.length}] ${percent}%`}>
                <Progress aria-labelledby="progresslabel" value={percent} />
            </FormItem>
        </Alert>
    )
}
export default FilesUpload