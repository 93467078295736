import React, {useEffect, useRef, useState} from "react"
import {
    Div, Group,
    Header,
    Panel, PanelHeader, Spinner, Input, FormItem, Button
} from "@vkontakte/vkui";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import api from "../../../components/Api";
import MySnack from "../../../alerts/MySnack";
import {useGlobalState} from "../../../components/States";

const Settings = ({id}) => {
    const [settings, setSettings] = useGlobalState("settings")
    const mapRef_ = useRef()
    const [snack, setSnack] = useState(null)
    const [map_center, setMapCenter] = useState(null)
    useEffect(() => {
        api.getSettings().then((r) => {
            setSettings(r.data.settings)
            setMapCenter(r.data.settings.map_center.split(","))
        })
    }, [])
    const changeCenter = e => {
        let val = e.currentTarget.value;
        if (!val.includes(",")) {
            setSnack(<MySnack error={true} setSnackbar={setSnack} mess={"Неправильно введенные координаты"}/>)
            return;
        }
        let set = {...settings}
        set.map_center = val;
        if(mapRef_.current)
            mapRef_.current.setCenter(val.split(","))
        setMapCenter(val.split(","))
        setSettings({...set})
    }
    const changeZoom = e => {
        let val = e.currentTarget.value;
        if (isNaN(parseFloat(val))) {
            setSnack(<MySnack error={true} setSnackbar={setSnack} mess={"Неправильно введенные данные, только цифры"}/>)
            return;
        }
        let set = {...settings}
        if(mapRef_.current)
            mapRef_.current.setZoom(parseFloat(val))
        set.map_zoom = parseFloat(val);
        setSettings({...set})
    }
    const save = e => {
        api.saveSettings(settings).then(r => {
            if (r.data.result) {
                setSnack(<MySnack setSnackbar={setSnack} mess={"Сохранено"}/>)
            } else {
                setSnack(<MySnack setSnackbar={setSnack} mess={r.data.mess} error={true}/>)
            }
        })
    }
    const changeInputFromMap = (center,zoom) =>{
            let set = {...settings}
            set.map_center = center.join(",");
            setMapCenter(center)
            set.map_zoom = zoom;

            setSettings({...set})
    }
    if (!settings)
        return <Spinner size={"large"}/>
    return (
        <Panel id={id}>
            <PanelHeader>
                Остальные настройки
            </PanelHeader>
            <Group header={<Header mode={"secondary"}>Настройки карты</Header>}>
                <div className="double-col">
                    <div className="double-col__col">
                        <div style={{height: "100%"}} className="">
                            <FormItem top={"Центр карты (или двигайте карту)"}>
                                <Input value={settings.map_center} onChange={changeCenter}/>
                            </FormItem>
                            <FormItem top={"Зум карты (или зумте карту)"}>
                                <Input value={settings.map_zoom} onChange={changeZoom}/>
                            </FormItem>
                            <FormItem>
                                <Button onClick={save} stretched size={"l"} appearance={"positive"}>
                                    Сохранить
                                </Button>
                            </FormItem>
                        </div>
                    </div>
                    <div className="double-col__col">
                        <YMaps>
                            <Div>
                                {map_center &&
                                    <Map
                                        instanceRef={mapRef=>{
                                            if(mapRef && !mapRef_.current) {
                                                mapRef_.current = mapRef
                                                mapRef.events.add('actionend', function (d) {
                                                    changeInputFromMap(mapRef.getCenter(),mapRef.getZoom());
                                                });
                                            }
                                        }}
                                        width={"100%"} height={600}
                                        defaultState={{center: map_center, zoom: settings.map_zoom}}>
                                    </Map>
                                }
                            </Div>
                        </YMaps>
                    </div>
                </div>

            </Group>

            {snack}
        </Panel>
    )
}
export default Settings