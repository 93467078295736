import axios from 'axios';
import {SITE_URL} from "./CONSTS";
class Api{
    #api = axios.create({
        baseURL: SITE_URL,
        timeout: 10000,
    });

    async uploadExcel(type,file,callback){
        let r = await this.#doUploadExcel(type,file,callback)
        return r.data
    }


    async getAllUsers(){
        return (await this.#doPostRequest({},"/user/getAllUsers"));
    }

    async getSettings(){
        return (await this.#doPostRequest({},"/settings/get"))
    }
    async saveSettings(data){
        return (await this.#doPostRequest(data,"/settings/save"))
    }



    //*ЗГРУЗКА ФОТОК*//
    async uploadImages(type,event_id,files,progress,complete){
        let photo = [];
        for (let i=0;i<files.length;i++){
            let r = await this.#doUpload(event_id,type,files[i],progress);
           photo.push(r.data)
            complete();
        }
        return photo;
    }
    async removeImage(id){
        let res = await this.#doPostRequest({id},"/user/removeImage");
        return res.data;
    }
    async sortImages(images){
        let res = await this.#doPostRequest({images},"/images/sort");
        return res.data;
    }
    //*ЗАГРУЗКА ФОТОК*//

    //*МЕСТА*//
    async setCategoryPlace(cat_id, val,id) {
        let res = await this.#doPostRequest({id,cat_id,val},"/places/category");
        return res.data;
    }
    async enableAllPlace(act) {
        let res = await this.#doPostRequest({act},"/places/enableall");
        return res.data;
    }
    async enablePlace(id){
        let res = await this.#doPostRequest({id},"/places/enable");
        return res.data;
    }
    async createPlace(name){
        let res = await this.#doPostRequest({name},"/places/create");
        return res.data;
    }
    async getPlaces(){
        let res = await this.#doPostRequest({},"/places/get");
        return res.data;
    }
    async removePlace(id){
        let res = await this.#doPostRequest({id},"/places/remove");
        return res.data;
    }
    async sortPlaces(map){
        let res = await this.#doPostRequest({map},"/places/sort");
        return res.data;
    }
    async editPlace(data,id){
        let res = await this.#doPostRequest({data,id},"/places/edit");
        return res.data;
    }
    //*МЕСТА*//

    //*МЕРОПРИЯТИЯ*//
    async removeEvent(id){
        let res = await this.#doPostRequest({id},"/events/remove");
        return res.data;
    }
    async setCategoryEvent(cat_id, val,id) {
        let res = await this.#doPostRequest({id,cat_id,val},"/events/category");
        return res.data;
    }
    async enableAllEvents(act){
        let res = await this.#doPostRequest({act},"/events/enableall");
        return res.data;
    }
    async enableEvents(id){
        let res = await this.#doPostRequest({id},"/events/enable");
        return res.data;
    }
    async createEvents(name){
        let res = await this.#doPostRequest({name},"/events/create");
        return res.data;
    }
    async getEvents(){
        let res = await this.#doPostRequest({},"/events/get");
        return res.data;
    }
    async removeEvents(id){
        let res = await this.#doPostRequest({id},"/events/remove");
        return res.data;
    }
    async sortEvents(map,sort_category=false){
        let res = await this.#doPostRequest({map,sort_category},"/events/sort");
        return res.data;
    }
    async editEvents(data,id){
        let res = await this.#doPostRequest({data,id},"/events/edit");
        return res.data;
    }
    //*МЕРОПРИЯТИЯ*//

    //*ПОДБОРКИ*//
    async createCollection(name){
        let res = await this.#doPostRequest({name},"/collections/create");
        return res.data;
    }
    async getCollections(){
        let res = await this.#doPostRequest({},"/collections/get");
        return res.data;
    }
    async sortCollPlaces(collection_id,map){
        let res = await this.#doPostRequest({map},"/collections/sortPlaces");
        return res.data;
    }
    async sortCollEvents(collection_id,map){
        let res = await this.#doPostRequest({map},"/collections/sortEvents");
        return res.data;
    }
    async removeCollections(id){
        let res = await this.#doPostRequest({id},"/collections/remove");
        return res.data;
    }
    async sortCollections(map){
        let res = await this.#doPostRequest({map},"/collections/sort");
        return res.data;
    }
    async editCollections(name=null,cats=null,date=null,id,isPlace=false,look_all = null){
        let res = await this.#doPostRequest({name,cats,date,id,isPlace,look_all},"/collections/edit");
        return res.data;
    }
    //*ПОДБОРКИ*//

    //*КАТЕГОРИИ*//
    async changeType(id,type){
        let res = await this.#doPostRequest({type,id},"/categories/setType");
        return res.data;
    }
    async createCategory(type,name){
        let res = await this.#doPostRequest({type,name},"/categories/create");
        return res.data;
    }
    async getCategories(type=null){
        let res = await this.#doPostRequest({type},"/categories/get");
        return res.data;
    }
    async removeCategory(id){
        let res = await this.#doPostRequest({id},"/categories/remove");
        return res.data;
    }
    async sortCat(map){
        let res = await this.#doPostRequest({map},"/categories/sort");
        return res.data;
    }
    async editCategory(name,id){
        let res = await this.#doPostRequest({name,id},"/categories/edit");
        return res.data;
    }
    //*КАТЕГОРИИ*//

    async addCollectionEvent(event_id,collection_id){
        let res = await this.#doPostRequest({collection_id,event_id},"/collections/addEvent");
        return res.data;
    }
    async removeCollectionEvent(event_id,collection_id){
        let res = await this.#doPostRequest({collection_id,event_id},"/collections/removeEvent");
        return res.data;
    }
    async getAllConnectedEvents(collection_id){
        let res = await this.#doPostRequest({collection_id},"/collections/getAllConnectedEvents");
        return res.data;
    }

    async addCollectionPlace(event_id,collection_id){
        let res = await this.#doPostRequest({collection_id,event_id},"/collections/addPlace");
        return res.data;
    }
    async removeCollectionPlace(event_id,collection_id){
        let res = await this.#doPostRequest({collection_id,event_id},"/collections/removePlace");
        return res.data;
    }



    async getInfo(){
        let res = await this.#doPostRequest({},"/user/getInfo");
        return res.data;
    }
    async logIn(email,pass){
        let res = await this.#doPostRequest({email,pass},"/user/login");
        return res;
    }
    async #doPostRequest(data,path){
        let res = {data: {result: false, mess: "error #1"}}
        let res_ = await this.#api.post(path, data,{withCredentials: true}).catch(()=>{});
        if(res_)
            return res_;
        else return res;
    }
    async #doUploadExcel(type,file,onUploadProgress){
        const formData = new FormData();
        formData.append("file", file);
        formData.append('type', type);
        let res = await this.#api.post("/images/uploadExcel", formData, {
            timeout: 40000,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,

        });
        return res;
    }
    async #doUpload(project_id,project_type,file,onUploadProgress){
        const formData = new FormData();
        formData.append("image", file);
        formData.append('project_id', project_id);
        formData.append('project_type', project_type);
        let res = await this.#api.post("/images/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,

        });
       return res;
    }


    async getAnalitic() {
        let res = await this.#doPostRequest({},"/an/get");
        return res.data;
    }

    async excelAnalyth(arr) {
        let res = await this.#doPostRequest({arr},"/an/excel");
        return res.data;
    }
}

export default new Api();