import React from "react"
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
Chart.register(ArcElement, Tooltip, Legend);

export const ChartForGender = ({genders,style}) => {
    const data = {
        labels: ['Ж.', 'М.',],
        datasets: [
            {
                label: 'Количество',
                data:genders,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, .2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
    return <div style={style}>
        <Pie data={data} />
    </div>
}