import React, {useEffect, useRef, useState} from "react"
import {
    Button,
    Header,Div,Group,FormItem,File,Search,
    Panel, PanelHeader, ScreenSpinner,Progress
} from "@vkontakte/vkui";
import {Icon24AddCircleOutline,Icon24Document} from "@vkontakte/icons";
import Api from "../../../components/Api";
import AddCategory from "../../../alerts/AddCategory";
import {useGlobalState} from "../../../components/States";
import MySnack from "../../../alerts/MySnack";
import Error from "../../../alerts/Error";
import EventCard from "../../../components/Event/EventCard";
import api from "../../../components/Api";
import {prependToArr} from "../../../components/CONSTS";
import Confirm from "../../../alerts/Confirm";

const Events = ({id,selectEvent,go}) =>{
    const [popout,setPopout] = useGlobalState("popout");
    const [snack,setSnack] = useState(null)
    const [search,setSearch] = useState("")
    const [progressExcel,setProgressExcel] = useState(null)
    const [events,setEvents] = useGlobalState("events");
    const inpRef = useRef();
    const addDocs = docs =>{
        let cats = [...docs,...events];
        setEvents([...cats])
    }
    const createEvent = (name) =>{
        setPopout(<ScreenSpinner size={"large"}/>)
        Api.createEvents(name).then(r => {
            if(!r.result){
                alert(r.mess,true)
            }else{
                let cats = [...events];
                cats = prependToArr({title:name,enable:false,id:parseInt(r.id)},cats);
                setEvents([...cats])
                alert("Мероприятие создано")
            }
        }).catch((r)=>{
            alert(r.message,true)
        });
    }
    const alert = (mess,err=false) =>{
        if(!err){
            setSnack(<MySnack mess={mess} setSnackbar={setSnack}/>);
        }else{
            setPopout(<Error closePopout={()=>setPopout(null)} mess={mess}/>)
        }
    }
    const addCat = () =>{
        setPopout(<AddCategory inputRef={inpRef} closePopout={()=>setPopout(null)} fn={createEvent}/>)
    }
    const enableEvent =(id) =>{
        Api.enableEvents(id).then(r=>{
            if(!r.result){
                alert(r.mess,true)
            }else{
                let cats = [...events];
                let index = cats.findIndex(x=>parseInt(x.id) === id)
                cats[index].enable = !cats[index].enable;
                setEvents([...cats])
                alert(`Мероприятие ${cats[index].enable ? "включено" : "выключено"}`)
            }
        })
    }
    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgressExcel(percent.toFixed(0))
    }
    const uploadFile = (e) =>{
        let file = e.currentTarget.files
        if(!file || file.length === 0)
            return;
        setPopout(<ScreenSpinner state="loading"/>);
        api.uploadExcel("events",file[0],onUploadProgress).then(r=>{
            if(!r.result){
                setPopout(null)
                setSnack(<MySnack mess={"Ошибка. Перезагрузите страницу, может это ложная ошибка "} error={true} setSnackbar={setSnack}/>)
            }else{
                setPopout(<ScreenSpinner state="done">Успешно</ScreenSpinner>);
                setProgressExcel(null)
                addDocs(r.data)
                setTimeout(()=>setPopout(null), 1000);
            }
        })
    }
    const activateAll = act =>{
        Api.enableAllEvents(act).then(r => {
            if (!r.result) {
                alert(r.mess, true)
            } else {
                let cats = [...events];
                cats.map(x=>x.enable = act);
                setEvents([...cats])
                alert(`Мероприятия ${act ? "включены" : "выключены"}`)
            }
        })
    }
    const filter = arr =>{
        if(!search || search.length < 3)
            return arr;
        else {
            return arr.filter(x=>x.title.toLowerCase().includes(search.toLowerCase()))
        }
    }
    const yesDeleteCard = id =>{
        setPopout(null)
        Api.removeEvents(id)
        let events_ = [...pla];
        let index = events_.findIndex(x=>x.id === id)
        events_.splice(index,1);
        setEvents([...events_])
    }
    const deleteCard = id =>{
        setPopout(<Confirm mess={"Уверены, что хотите удалить это мероприятие?"} closePopout={()=>setPopout(null)} fn={()=>yesDeleteCard(id)}/>)
    }
    return (
        <Panel id={id}>
            <PanelHeader>
                Мероприятия
            </PanelHeader>
            <Div>

            </Div>
            <Div className={"p-div"}>
                <Group  header={
                    <div className="div-header">
                            <Header mode={"secondary"}>Список</Header>

                        <div className={"div-row"}>
                            <Button onClick={()=>activateAll(true)} appearance={"positive"}>
                                Активировать все
                            </Button>
                            <Button onClick={()=>activateAll(false)} appearance={"negative"}>
                                Деактивировать все
                            </Button>
                            <FormItem top="Автозагрузка">
                                <File accept=".xl*" onChange={uploadFile} before={<Icon24Document role="presentation" />} size="l" mode="secondary" />
                                {progressExcel && progressExcel >= 0 &&
                                    <Progress value={progressExcel}/>
                                }
                            </FormItem>
                            <Button onClick={addCat} before={<Icon24AddCircleOutline/>} mode={"primary"} appearance={"positive"}>
                                Добавить мероприятие
                            </Button>
                        </div>

                    </div>
                }>
                    <Search style={{width:300}} value={search} onChange={(e)=>setSearch(e.currentTarget.value)} />
                    <div className={"group-row"}>
                        {events && filter(events).map((x,k)=>(
                            <EventCard
                                deleteEvent={()=>deleteCard(x.id)}
                                onClick={()=>selectEvent(x)}
                                enableBox={true}
                                enable={x.enable}
                                key={k}
                                enableMe={()=>enableEvent(parseInt(x.id))}
                                description={x.sub_title}
                                title={x.title}
                                img={x.photos && x.photos.length ? x.photos[0].path : ""}
                            />
                        ))}
                    </div>

                </Group>
            </Div>
            {snack}
        </Panel>
    )
}
export default Events