import React, {useState} from 'react';
import {Panel, ScreenSpinner} from '@vkontakte/vkui';

const Load = ({id}) => {

    return (
        <Panel id={id}>
            <ScreenSpinner />
        </Panel>
    )
};


export default Load;
