import React, {useEffect, useRef, useState} from "react"
import {
    Panel,
    PanelHeader,
    File,
    PanelHeaderBack,
    Group,
    Header,
    FormItem,
    Checkbox,
    Input,
    Textarea,
    Button,
    ScreenSpinner,
    Div,
    CustomSelect
} from "@vkontakte/vkui";
import {Icon24Document} from "@vkontakte/icons"
import {useGlobalState} from "../../../components/States";
import MyDateInput from "../../../components/MyDateInput";
import MySnack from "../../../alerts/MySnack";
import Api from "../../../components/Api";
import FilesUpload from "../../../alerts/FilesUpload";
import List from "../../../components/Photos/List";
import {Map, Placemark, YMaps} from "react-yandex-maps";

const PlacesEdit = ({id, go, selectedPlace, setselectedPlace}) => {
    const [settings, setSettings] = useGlobalState("settings")
    const [popout, setPopout] = useGlobalState("popout");
    const [events, setEvents] = useGlobalState("places");
    const [categories, setCategories] = useGlobalState("categories");
    const [snack, setSnack] = useState(null)
    const [map, setMap] = useState("")
    const [photos, setPhotos] = useState(null)
    const [times, setTimes] = useState(null)
    const [data, setData] = useState({
        title: selectedPlace.title,
        sub_title: selectedPlace.sub_title,
        adres: selectedPlace.adres,
        description: selectedPlace.description,
        sites: selectedPlace.sites,
        phone: selectedPlace.phone,
    })
    const mapRef = useRef()
    const day = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]
    const [date, setDate] = useState()

    useEffect(() => {
        if (selectedPlace && selectedPlace.time) {
            setTimes(JSON.parse(selectedPlace.time))
        }else setTimes([])
        if (selectedPlace && selectedPlace.map) {
            setMap(JSON.parse(selectedPlace.map))
        }
        if (selectedPlace && selectedPlace.photos && Array.isArray(selectedPlace.photos)) {
            setPhotos(selectedPlace.photos)
        }
    }, [])
    const changeCheckBox = e => {
        let id = parseInt(e.currentTarget.dataset.id);
        let val = e.currentTarget.checked;
        Api.setCategoryPlace(id, val, selectedPlace.id);
    }
    const changeInput = e => {
        let val = e.currentTarget.value;
        let col = e.currentTarget.dataset.col;
        let d = {...data};
        if(col === "time"){
            val = JSON.stringify(val.split("\n"));
        }
        d[col] = val;
        setData({...d})
    }
    const defChecked = e => {
        if (selectedPlace && selectedPlace.categories) {
            return selectedPlace.categories.find(x => parseInt(x.category_id) === parseInt(e))
        }
        return false;

    }
    const save = () => {
        if (!data.title) {
            setSnack(<MySnack setSnackbar={setSnack} mess={"Введите имя места"} error={true}/>)
            return;
        }
        setPopout(<ScreenSpinner size={"large"}/>)
        let d = {...data};
        d.map = map ? JSON.stringify(map) : ""
        Api.editPlace(d, selectedPlace.id).then((r) => {
            if (!r.result) {
                setSnack(<MySnack setSnackbar={setSnack} mess={r.mess} error={true}/>)
            } else {
                let s = {...selectedPlace, ...data};
                let index = events.findIndex(x => parseInt(x.id) === parseInt(selectedPlace.id));
                setselectedPlace(s);
                let all_s = [...events];
                all_s[index] = {...s};
                setEvents(all_s);
                setSnack(<MySnack setSnackbar={setSnack} mess={"Данные сохранены"}/>)
            }
            setPopout(null)
        }).catch(r => {
            setPopout(null)
            alert(r.message)
        })
    }
    const filesWasUpload = e => {
        let ph = Array.isArray(photos) ? [...photos] : [];
        ph.push(...e)
        setPhotos([...ph])
        setPopout(null)
    }
    const uploadFiles = (e) => {
        setPopout(<FilesUpload
            fn={filesWasUpload}
            project_type={"place"}
            project_id={selectedPlace.id}
            files={e.currentTarget.files}
            closePopout={() => setPopout(null)}/>)
    }
    const changeTime = (e) => {
        let key = e.currentTarget.dataset.id;
        let times_ = {...times};
        times_[key] = e.currentTarget.value;
        setTimes({...times_})
    }
    const changeMap = (e) =>{
        if(!Array.isArray(e)){
            let c = e.currentTarget.value.split(",");
            if(c.length === 2)
                setMap(c)
            else{
                setSnack(<MySnack setSnackbar={setSnack} error={true} mess={"Нужны координаты вида 45.33223,43.3211"}/>)
            }
        }else{
            setMap(e)
        }
    }
    const removePhoto = id =>{
        let ph = [...photos];
        let events_ = [...events];
        let index = ph.findIndex(x=>x.id === id);
        if(index >= 0){
            ph.splice(index,1)
            setPhotos([...ph]);
            let eventIndex = events_.findIndex(x=>x.id === selectedPlace.id);
            events_[eventIndex].photos = [...ph];
            setEvents(events_);
        }
    }
    return (
        <Panel id={id}>
            <PanelHeader
                before={<PanelHeaderBack onClick={go} data-to={"home"}/>}
            >
                Место - {selectedPlace ? selectedPlace.title : ""}
            </PanelHeader>
            <Group className={"div-f"} header={<Header mode={"secondary"}>Настройки места</Header>}>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Название"}>
                        <Input defaultValue={data.title} onChange={changeInput} data-col={"title"}/>
                    </FormItem>
                    {/*<FormItem top={"Подзаголовок"}>
                        <Input defaultValue={data.sub_title} onChange={changeInput} data-col={"sub_title"}/>
                    </FormItem>*/}

                </div>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Телефон"}>
                        <Input defaultValue={data.phone} onChange={changeInput} data-col={"phone"}/>
                    </FormItem>
                    <FormItem top={"Карта (Координаты, или выберите на карте ниже)"}>
                        <Input value={map} onChange={changeMap} data-col={"map"}/>
                    </FormItem>
                    <FormItem top={"Адрес"}>
                        <Input defaultValue={data.adres} onChange={changeInput} data-col={"adres"}/>
                    </FormItem>
                </div>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Сайты (через запятую)"}>
                        <Textarea defaultValue={data.sites} onChange={changeInput} data-col={"sites"}/>
                    </FormItem>
                    <FormItem top={"Описание"}>
                        <Textarea defaultValue={data.description} onChange={changeInput} data-col={"description"}/>
                    </FormItem>
                </div>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Режим работы"}>
                        <div className="div-row div-row__stretched">
                            {times !== null &&
                                <Textarea defaultValue={Array.isArray(times) ? times.join("\n") : ""} onChange={changeInput} data-col={"time"}/>
                            }

                            {/*{Object.keys(times).map((x, k) => (
                                <FormItem top={day[parseInt(x)] + ' (пусто выходной)'}>
                                    <Input defaultValue={times[x]} data-id={x} onChange={changeTime}
                                           data-col={"sub_title"}/>
                                </FormItem>
                            ))}*/}
                        </div>
                    </FormItem>
                </div>

                <FormItem>
                    <Button onClick={save} size={"m"} stretched appearance={"positive"}>Сохранить</Button>
                </FormItem>


                {/* <FormItem top={"Категории 1"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 0).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>
                <FormItem top={"Категории 2"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 1).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>*/}
                <FormItem top={"Категории"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 2).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>

            </Group>
            <Group header={<Header mode={"secondary"}>Фотографии места</Header>}>
                <FormItem top="Загрузите документы">
                    <File multiple onInput={uploadFiles} before={<Icon24Document role="presentation"/>} size="l"
                          mode="secondary"/>
                </FormItem>
                <Div>
                    {Array.isArray(photos) && !photos.length &&
                        <span>Фотографий нет</span>
                    }
                    {!Array.isArray(photos) &&
                        <span>Фотографии не загружены</span>
                    }
                    {Array.isArray(photos) && photos.length > 0 &&
                        <List removePhoto={removePhoto} setImages={setPhotos} images={photos}/>
                    }
                </Div>
            </Group>
            <Group header={<Header mode={"secondary"}>Карта места</Header>}>
                <YMaps>
                    <Div>
                        <Map
                            width={"100%"} height={600} defaultState={{
                                center: data.map ? data.map :(settings ? settings.map_center.split(",") :[55.75, 37.57]),
                            zoom: settings ? settings.map_zoom :9
                        }} >
                            <Placemark
                                instanceRef={e=> {
                                    if(e){
                                        e.events.add('dragend', function (d) {
                                            changeMap(e.geometry._coordinates)
                                        });
                                    }

                                }}
                                options={{draggable:true}} geometry={map ? map :[55.75, 37.57]} />
                        </Map>
                    </Div>
                </YMaps>
            </Group>
            {snack}
        </Panel>
    )
}
export default PlacesEdit