import React, {useState, useEffect} from 'react';
import {View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import Home from './panels/Home';
import "./css/main.scss"
import "./css/buttons.scss"
import {useGlobalState} from "./components/States";
import Api from "./components/Api";
import Load from "./panels/Load";
import Auth from "./panels/Auth";
import CollectionEdit from "./panels/SubPanels/Collections/CollectionEdit";
import EventEdit from "./panels/SubPanels/Events/EventEdit";
import Places from "./panels/SubPanels/Places/Places";
import PlacesEdit from "./panels/SubPanels/Places/PlacesEdit";
import api from "./components/Api";
const App = () => {
    const [settings, setSettings] = useGlobalState("settings")
    const [activePanel, setActivePanel] = useState('load');
    const [activeSubPanel, setActiveSubPanel] = useState('users');
    const [fetchedUser, setUser] = useState(null);
    const [popout,setPopout] = useGlobalState("popout");
    const [categories,setCategories] = useGlobalState("categories");
    const [collections,setCollections] = useGlobalState("collections");
    const [analyth,setAnalyth] = useGlobalState("analyth");
    const [events,setEvents] = useGlobalState("events");
    const [places,setPlaces] = useGlobalState("places");
    const [selectedCollection,setSelectedCollection] = useState(null)
    const [selectedEvent,setSelectedEvent] = useState(null)
    const [selectedPlace,setSelectedPlace] = useState(null)

    useEffect(() => {
        Api.getInfo().then(r=>{
            if(!r.result && r.mess && r.mess.includes("not auth")) {
                setActivePanel("auth");
            }else {
                getAllInfo();

            }
        })
    }, []);
    const getAllInfo = async () =>{
        let cats = await Api.getCategories();
        let collections_ = await Api.getCollections();
        let events_ = await Api.getEvents();
        let places_ = await Api.getPlaces();
        let analyth_ = await Api.getAnalitic();
        api.getSettings().then((r) => {
            setSettings(r.data.settings)
        })
        setAnalyth(analyth_)
        setCategories(cats.data)
        setCollections(collections_.data)
        setEvents(events_.data)
        setPlaces(places_.data)
        setActivePanel("home")
    }
    const goToMain = e =>{
        if (typeof e !== "string")
            setActivePanel(e.currentTarget.dataset.to);
        else
            setActiveSubPanel(e);
    }
    const go = e => {

        if (typeof e !== "string")
            setActiveSubPanel(e.currentTarget.dataset.to);
        else
            setActiveSubPanel(e);
    };
    const openCollection = (x) =>{
        setSelectedCollection(x);
        setActivePanel("collection")
    }
    const selectEvent = (x) =>{
        setSelectedEvent(x);
        setActivePanel("event")
    }
    const selectPlace = (x) =>{
        setSelectedPlace(x);
        setActivePanel("place")
    }
    return (
        <ConfigProvider appearance={"light"}>
            <AdaptivityProvider>
                <AppRoot>
                    <SplitLayout popout={popout}>
                        <SplitCol>
                            <View activePanel={activePanel}>
                                <Home selectPlace={selectPlace} selectEvent={selectEvent} openCollection={openCollection} goToMain={goToMain} setPopout={setPopout} id='home' activePanel={activeSubPanel} fetchedUser={fetchedUser} go={go}/>
                                <CollectionEdit setSelectedCollection={setSelectedCollection} go={goToMain} id={"collection"} selectedCollection={selectedCollection}/>
                                <EventEdit setSelectedEvent={setSelectedEvent} go={goToMain} id={"event"} selectedEvent={selectedEvent}/>
                                <PlacesEdit id={"place"} go={goToMain} setselectedPlace={setSelectedPlace} selectedPlace={selectedPlace}/>
                                <Load id={"load"}/>
                                <Auth getAll={getAllInfo} id={"auth"}/>
                            </View>
                        </SplitCol>
                    </SplitLayout>
                </AppRoot>
            </AdaptivityProvider>
        </ConfigProvider>
    );
}

export default App;
