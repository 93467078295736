import React, {useEffect, useState} from "react"
import {PanelHeader, Panel, Div, Cell, Avatar, FormItem, Header, Checkbox, Group, Placeholder} from "@vkontakte/vkui";
import {useGlobalState} from "../../../components/States";
import {getImage} from "../../../components/CONSTS";
import Api from "../../../components/Api";


const SortPlaces = ({id}) => {
    const [selectedCategories, setSelectedCategories] = useState([])
    const [categories, setCategories] = useGlobalState("categories");
    const [places, setPlaces] = useGlobalState("places")
    const [activeEvents, setActiveEvents] = useState([])
    const selectCategory = e => {
        let id = parseInt(e)
        let c = selectedCategories ? [...selectedCategories] : [];
        let index = c.indexOf(e);
        if (index >= 0) {
            c.splice(index, 1)
        } else {
            c = [id];
        }
        setSelectedCategories(c)
    }
    useEffect(() => {
        if (selectedCategories && selectedCategories.length) {
            let arr = [...places];
            setActiveEvents(arr.filter(x => x.categories.some(r => selectedCategories.includes(r.category_id))))
        } else {
            setActiveEvents([])
        }
    }, [selectedCategories])
    const getCategories = (type) => {
        return categories.filter(x => x.type === type);
    }
    const reorderList = ({ from, to }, list, updateListFn) => {
        from -= 1;
        to -= 1;
        const _list = [...list];
        _list.splice(from, 1);
        _list.splice(to, 0, list[from]);
        let map = _list.map((x,k)=>{
            if(x) {
                x.sort_category = k;
                return {id: x.id,sort:k, sort_category: k}
            }
        })
        Api.sortPlaces(map,true)
        updateListFn(_list);
    };
    return (
        <Panel id={id}>
            <PanelHeader>
                Сортировка мероприятий в категории
            </PanelHeader>
            <Group>
                <div className="categories">
                    <Div style={{width: "100%"}} className="div-row div-row__two">
                        <div style={{flex: 1}} className="div-row div-row_wrap">
                            {getCategories(2).sort((a, b) => a.sort - b.sort).map((x, k) => (
                                <FormItem className={"category div-row__content"}>
                                    <Checkbox checked={selectedCategories && selectedCategories.indexOf(x.id) >= 0}
                                              onChange={() => selectCategory(x.id)} key={k}>{x.name}</Checkbox>
                                </FormItem>
                            ))}
                        </div>
                    </Div>
                </div>
            </Group>
            <Group header={<Header>Места</Header>}>
                {activeEvents && (activeEvents.length > 0 || selectedCategories.length > 0) ? (
                        activeEvents.length === 0 ?
                            <Placeholder>
                                Ничего нет
                            </Placeholder>
                            :
                            activeEvents.sort((a, b) => a.sort_category - b.sort_category).map((x, k) => {
                                if(x)
                                    return(
                                    <Cell
                                        key={k}
                                        draggable
                                        before={<Avatar size={48}
                                                        src={x.photos && x.photos.length > 0 ? getImage(x.photos[0].path) : "https://upload.wikimedia.org/wikipedia/commons/9/9a/%D0%9D%D0%B5%D1%82_%D1%84%D0%BE%D1%82%D0%BE.png"}/>}
                                        onDragFinish={({ from, to }) =>
                                            reorderList({ from, to }, activeEvents, setActiveEvents)
                                        }
                                    >
                                        {x.title}
                                    </Cell>
                                )
                            })
                    )
                    :
                    <Placeholder>
                        Выберите категорию
                    </Placeholder>
                }
            </Group>
        </Panel>
    )
}
export default SortPlaces