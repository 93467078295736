import React, {useEffect} from "react"
import {SITE_URL} from "../CONSTS";
import PhotoCardEdit from "./PhotoCardEdit";
import Api from "../Api";
import api from "../Api";

const List = ({images,removePhoto,setImages}) =>{
    const reorderList = ({ from, to }) => {
        //reorderList({from, to}, collections, setCollections)
        /*from -= 1;
        to -= 1;*/
        let list = [...images];
        const _list = [...list];
        _list.splice(from, 1);
        _list.splice(to, 0, list[from]);
        let map = _list.map((x,k)=>{
            if(x) {
                x.sort = k;
                return {id: x.id, sort: k}
            }
            return x;
        })
        Api.sortImages(map)
        setImages(_list);
    }

    const deleteImg = (id) => {
        removePhoto(id);
        api.removeImage(id)
    }
    return (
        <div className={"photos-list"}>
            {images && images.sort((a,b)=>a.sort-b.sort).map((x,k)=>(
                <PhotoCardEdit key={k} deleteImg={()=>deleteImg(x.id)} reorderList={reorderList} url={`${SITE_URL}/images/get?i=${x.path}`}/>
            ))}
        </div>
    )
}
export default List