import React, {useState} from "react"
import {CellButton} from "@vkontakte/vkui";
import {Icon24Dropdown, Icon24ListBulletOutline, Icon24Users3Outline} from "@vkontakte/icons";
import {useGlobalState} from "./States";
import { Icon24KeyboardBotsOutline,Icon24SortOutline,Icon28NewsfeedLinesOutline,Icon28LocationMapOutline } from '@vkontakte/icons';
import { Icon28GraphOutline,Icon28SettingsOutline } from '@vkontakte/icons';

const Menu = ({go,activePanel}) =>{
    const [opened,setOpened] = useState([false])
    const [categoriesType,setCategoriesType] = useGlobalState("categoriesType");
    const [categories,setCategories] = useGlobalState("categories");
    const open = (e) =>{
        let o = [...opened]
        let id = parseInt(e.currentTarget.dataset.id)
        o[id] = !o[id];
        setOpened([...o]);
    }
    return (
        <div className="menu">
            <CellButton
                before={<Icon24Users3Outline/>}
                onClick={()=>{
                    go("users");
                }}
                className={`${activePanel === "users" ? "but-active" : ""}`}
            >
                Пользователи
            </CellButton>
            <div className={`menu-sub ${opened[0] ? 'menu-sub__opened' : ''}`}>
                <CellButton
                    onClick={open}
                    data-id={0}
                    className={`${activePanel === "categories" ? "but-active" : ""}`}
                    after={<Icon24Dropdown style={{transform:`rotateZ(${opened[0] ? '180' : '0'}deg)`}}/>}
                    before={<Icon24ListBulletOutline/>}
                >
                    Категории ({categories && categories.length})
                </CellButton>
                <div className="menu-sub__content">
                    <CellButton onClick={()=>{
                        go("categories");
                        setCategoriesType(0)
                    }} className={`${activePanel === "categories" && categoriesType === 0 ? "but-active" : ""}`}>
                        Категории 1 ({categories && categories.filter(x=>x && parseInt(x.type)===0).length})
                    </CellButton>
                    <CellButton onClick={()=>{
                        go("categories");
                        setCategoriesType(1)
                    }} className={`${activePanel === "categories" && categoriesType === 1 ? "but-active" : ""}`}>
                        Категории 2 ({categories && categories.filter(x=>x && parseInt(x.type)===1).length})
                    </CellButton>
                    <CellButton onClick={()=>{
                        go("categories");
                        setCategoriesType(2)
                    }} className={`${activePanel === "categories" && categoriesType === 2 ? "but-active" : ""}`}>
                        Категории мест ({categories && categories.filter(x=>x && parseInt(x.type)===2).length})
                    </CellButton>
                </div>
            </div>
            <CellButton
                before={<Icon24SortOutline/>}
                className={`${activePanel === "sort-meroprs" ? "but-active" : ""}`}
                onClick={()=>{
                    go("sort-meroprs");
                }}
            >
                Сортировка мероприятий в категории
            </CellButton>
            <CellButton
                before={<Icon24SortOutline/>}
                className={`${activePanel === "sort-places" ? "but-active" : ""}`}
                onClick={()=>{
                    go("sort-places");
                }}
            >
                Сортировка мест в категории
            </CellButton>
            <CellButton
                before={<Icon24KeyboardBotsOutline/>}
                className={`${activePanel === "collections" ? "but-active" : ""}`}
                onClick={()=>{
                    go("collections");
                }}
            >
                Подборки
            </CellButton>
            <CellButton
                before={<Icon28NewsfeedLinesOutline/>}
                className={`${activePanel === "events" ? "but-active" : ""}`}
                onClick={()=>{
                    go("events");
                }}
            >
                Мероприятия
            </CellButton>
            <CellButton
                before={<Icon28LocationMapOutline/>}
                className={`${activePanel === "places" ? "but-active" : ""}`}
                onClick={()=>{
                    go("places");
                }}
            >
                Места
            </CellButton>
            <CellButton
                before={<Icon28GraphOutline/>}
                className={`${activePanel === "analyth" ? "but-active" : ""}`}
                onClick={()=>{
                    go("analyth");
                }}
            >
                Аналитика
            </CellButton>
            <CellButton
                before={<Icon28SettingsOutline/>}
                className={`${activePanel === "settings" ? "but-active" : ""}`}
                onClick={()=>{
                    go("settings");
                }}
            >
                Остальные настройки
            </CellButton>
        </div>
    )
}
export default Menu