import React, {useEffect, useRef, useState} from "react"
import {
    Panel, PanelHeader,SegmentedControl, PanelHeaderBack, Group, Header, FormItem, Checkbox, Link, Search, Div, Placeholder, Cell, Avatar
} from "@vkontakte/vkui";
import {useGlobalState} from "../../../components/States";
import EventsGallery from "../../../components/Event/EventsGallery";
import EventCard from "../../../components/Event/EventCard";
import MyDateInput from "../../../components/MyDateInput";
import MyDateRange from "../../../components/MyDateRange";
import Api from "../../../components/Api";
import Loading from "../../../components/Loading";
import EventAdd from "../../../components/Event/EventAdd";
import api from "../../../components/Api";
import {getImage} from "../../../components/CONSTS";

const CollectionsEdit = ({id, go, selectedCollection, setSelectedCollection}) => {
    const [popout, setPopout] = useGlobalState("popout");
    const [events, setEvents] = useGlobalState("events");
    const [places, setPlaces] = useGlobalState("places");
    const [collections, setCollections] = useGlobalState("collections");
    const [categories, setCategories] = useGlobalState("categories");
    const [isPlace,setIsPlace]= useState(false)
    const [lookAll,setLookAll]= useState(false)
    const [types,setTypes]= useState(0)
    const [addedEvents, setAddedEvents] = useState([])
    const [addedPlaces, setAddedPlaces] = useState([])
    const [snack, setSnack] = useState(null)
    const [search, setSearch] = useState("")
    const [preview, setPreview] = useState(null)
    const [loadingPreview, setLoadingPreview] = useState(false)

    const [date, setDate] = useState()
    const changeDate = (date_) => {
        let s = {...selectedCollection};
        s.date = date_;
        setSelectedCollection({...s})
        let c = [...collections];
        c[c.findIndex(x => parseInt(selectedCollection.id) === parseInt(x.id))] = {...s};
        setCollections([...c]);
        setDate(date_)
        Api.editCollections(null, null, date_, selectedCollection.id,isPlace,lookAll)
    }
    useEffect(() => {
        if (selectedCollection) {
            setIsPlace(!!selectedCollection.isPlace)
            setLookAll(!!selectedCollection.look_all)
            api.getAllConnectedEvents(selectedCollection.id).then(r => {
                if (r.result) {
                    let re = [];
                    /*r.data.map(x => {
                        re.push({collection_id: selectedCollection.id, event_id: x.event_id})
                    })*/
                    //console.log(r.data)
                    setAddedEvents([...r.data.events])
                    setAddedPlaces([...r.data.places])
                }
            })
        }
    }, [selectedCollection])
    useEffect(() => {
        if (selectedCollection && selectedCollection.date && selectedCollection.date !== "null") {
            let s = {...selectedCollection};
            if (typeof s.date === "string") {
                s.date = JSON.parse(s.date).map(x => new Date(x));
                setSelectedCollection({...s})
            }
            setDate([...s.date])
            if (selectedCollection.items) {
                getPreview(selectedCollection.items);
            }

        } else if (selectedCollection && selectedCollection.items) {
            getPreview(selectedCollection.items);
        }
    }, [])
    const getPreview = (items) => {
        let s = [];
        let events = [...items.events];
        let places = [...items.places];
        let prevItem = "";
        let l = events.length + places.length;
        for (let i = 0; i < l; i++) {
            if ((places.length === 0 && events.length > 0) || (events.length > 0 && prevItem !== "event")) {
                s.push({...events[0], type: "Мероприятие"});
                events.splice(0, 1);
                prevItem = "event";
            }

            if ((events.length === 0 && places.length > 0) || (places.length > 0 && prevItem !== "place")) {
                s.push({...places[0], type: "Место"});
                places.splice(0, 1);
                prevItem = "place"
            }
            if (!events.length && !places.length) {
                break;
            }
        }
        setLoadingPreview(false)
        setPreview([...s]);
    }
    const changeCheckBox = e => {
        setLoadingPreview(true)
        let id = parseInt(e.currentTarget.dataset.id);
        let val = e.currentTarget.checked;
        let s = {...selectedCollection};
        if ((!s.cats_id || (!Array.isArray(s.cats_id) && (s.cats_id === "null")) || !s.cats_id.length) && val) {
            s.cats_id = [id]
        } else {
            if (typeof s.cats_id === "string")
                s.cats_id = JSON.parse(s.cats_id)
            if (!val) {
                let index = s.cats_id.findIndex(x => x === id);
                if (index >= 0) {
                    s.cats_id.splice(index, 1);
                }
            } else {
                s.cats_id.push(id)
            }
        }
        setSelectedCollection({...s})
        let c = [...collections];
        c[c.findIndex(x => parseInt(selectedCollection.id) === parseInt(x.id))] = {...s};
        setCollections([...c]);
        Api.editCollections(null, s.cats_id, null, selectedCollection.id,isPlace,lookAll).then(r => {
            if (r.result) {
                c[c.findIndex(x => parseInt(selectedCollection.id) === parseInt(x.id))] = {...s, items: r.items};
                setCollections([...c]);
                getPreview(r.items)
            }
        })
    }
    const defChecked = e => {
        if (selectedCollection) {
            let id = parseInt(e);
            if (selectedCollection === "null")
                return false;
            else {
                let json;
                if (typeof selectedCollection.cats_id === "string" && selectedCollection.cats_id !== "null")
                    json = JSON.parse(selectedCollection.cats_id)
                else if (selectedCollection.cats_id !== "null")
                    json = selectedCollection.cats_id
                else return false;
                return json.findIndex(x => parseInt(x) === id) >= 0;
            }

        }
        return false;

    }
    const filterEventsAdd = arr => {
        if (!search)
            return arr;
        else {
            return arr.filter(x => x.title.toLowerCase().includes(search.toLowerCase()) || (x.description && x.description.toLowerCase().includes(search.toLowerCase())));
        }
    }
    const isEnable = x => {
        let add = [...addedEvents];
        let index = add.findIndex(d => d.event_id === x.id);
        return index >= 0;
    }
    const enableMe = x => {
        let add = [...addedEvents];
        let index = add.findIndex(d => d.event_id === x.id);
        if (index >= 0) {
            add.splice(index, 1);
            api.removeCollectionEvent(x.id, selectedCollection.id)
        } else {
            add.push({...x,id:x.id,event_id: x.id, collection_id: selectedCollection.id})
            api.addCollectionEvent(x.id, selectedCollection.id)
        }
        setAddedEvents([...add])
    }
    const isEnablePlace = x => {
        let add = [...addedPlaces];
        let index = add.findIndex(d => d.event_id === x.id);
        return index >= 0;
    }
    const enableMePlace = x => {
        let add = [...addedPlaces];
        let index = add.findIndex(d => d.event_id === x.id);
        if (index >= 0) {
            add.splice(index, 1);
            api.removeCollectionPlace(x.id, selectedCollection.id)
        } else {
            add.push({...x,id:x.id,event_id: x.id, collection_id: selectedCollection.id})
            api.addCollectionPlace(x.id, selectedCollection.id)
        }
        setAddedPlaces([...add])
    }
    const reorderList = ({ from, to }, list, updateListFn,type=0) => {
        from -= 1;
        to -= 1;
        const _list = [...list];

        _list.splice(from, 1);
        _list.splice(to, 0, list[from]);
        let map = _list.map((x,k)=>{
            if(x) {
                x.sorted = k;
                x.sort = k;
                return {id:x.c_id, sort: k, sorted: k}
            }
        })
        if(!type)
            Api.sortCollEvents(selectedCollection.id,map)
        else
            Api.sortCollPlaces(selectedCollection.id,map)
        updateListFn(_list);
    };
    const placeIs = e =>{
        setIsPlace(e.target.checked)
        let s = {...selectedCollection}
        s.isPlace = e.target.checked ? 1 : 0;
        saveColl(s)
        api.editCollections(null,null,null, selectedCollection.id,e.target.checked,lookAll)
    }
    const saveColl = c => {
        let cols = [...collections];
        let index = cols.findIndex(x=>x.id === c.id)
        cols[index] = c;
        setCollections([...cols])
    }
    const lookAllIs = e =>{
        setLookAll(e.target.checked)
        let s = {...selectedCollection}
        s.look_all = e.target.checked ? 1 : 0;
        saveColl(s)
        api.editCollections(null,null,null, selectedCollection.id,isPlace,e.target.checked)
    }
    return (
        <Panel id={id}>
            <PanelHeader
                before={<PanelHeaderBack onClick={go} data-to={"home"}/>}
            >
                Подборка - {selectedCollection ? selectedCollection.name : ""}
            </PanelHeader>
            <Group className={"div-f"} header={<Header mode={"secondary"}>Настройки подборки</Header>}>
                <FormItem top={"Категории 1"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 0).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>
                <FormItem top={"Категории 2"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 1).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>
                <FormItem top={"Категории мест"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 2).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>
                <FormItem top={"Подборка мест ??"}>
                    <Checkbox checked={isPlace} onChange={placeIs}>Преходить в приложении в места</Checkbox>
                </FormItem>
                <FormItem top={"Кнопка «Смотреть все»"}>
                    <Checkbox checked={lookAll} onChange={lookAllIs}>Отображать кнопку - «Смотреть все»</Checkbox>
                </FormItem>
                <FormItem top={"Время для фильтров"}>
                    <MyDateRange value={date} setValue={changeDate}/>
                </FormItem>
            </Group>

            <Group header={<Header mode={"secondary"}>Добавить вручную</Header>}>
                    <Div style={{width: "100%"}}>
                        <SegmentedControl
                            onChange={(e) => {
                                setTypes(e)
                            }}
                            value={types}
                            options={[
                                {
                                    'label': `Мероприятия`,
                                    'value': 0,
                                    'aria-label': 'Плитки',
                                },
                                {
                                    'label': `Места`,
                                    'value': 1,
                                    'aria-label': 'Список',
                                },

                            ]}
                        />
                    </Div>
                {types === 1 &&
                    <div>
                        <div className="row">
                            <Search value={search} onChange={e => setSearch(e.currentTarget.value)}/>
                        </div>

                        <EventsGallery>

                            {places && filterEventsAdd(places).sort(function (a, b) {
                                return addedPlaces.findIndex(x => x.event_id === b.id) - addedPlaces.findIndex(x => x.event_id === a.id)
                            }).map((n, ko) => (
                                <EventAdd
                                    onClick={() => enableMePlace(n)}
                                    enabled={() => isEnablePlace(n)}
                                    key={ko}
                                    disabled={true}
                                    img={n.photos && n.photos.length ? n.photos[0].path : ""}
                                    title={n.title} description={n.description}/>
                            ))}

                        </EventsGallery>

                        <FormItem top={"Сортировка"}>
                            {addedPlaces &&
                            addedPlaces.length === 0 ?
                                <Placeholder>
                                    Ничего нет
                                </Placeholder>
                                :
                                addedPlaces.sort((a, b) => a.sorted - b.sorted).map((x, k) => {
                                    if (x)
                                        return (
                                            <Cell
                                                key={k}
                                                draggable
                                                onDragFinish={({from, to}) =>
                                                    reorderList({from, to}, addedPlaces, setAddedPlaces,1)
                                                }
                                            >
                                                {x.title}
                                            </Cell>
                                        )
                                })
                            }
                        </FormItem>

                    </div>
                }

                {types === 0 &&
                    <div>
                        <div className="row">
                            <Search value={search} onChange={e => setSearch(e.currentTarget.value)}/>
                        </div>

                        <EventsGallery>

                            {events && filterEventsAdd(events).sort(function (a, b) {
                                return addedEvents.findIndex(x => x.event_id === b.id) - addedEvents.findIndex(x => x.event_id === a.id)
                            }).map((n, ko) => (
                                <EventAdd
                                    onClick={() => enableMe(n)}
                                    enabled={() => isEnable(n)}
                                    key={ko}
                                    disabled={true}
                                    img={n.photos && n.photos.length ? n.photos[0].path : ""}
                                    title={n.title} description={n.description}/>
                            ))}

                        </EventsGallery>

                        <FormItem top={"Сортировка"}>
                            {addedEvents &&
                            addedEvents.length === 0 ?
                                <Placeholder>
                                    Ничего нет
                                </Placeholder>
                                :
                                addedEvents.sort((a, b) => a.sorted - b.sorted).map((x, k) => {
                                    if (x)
                                        return (
                                            <Cell
                                                key={k}
                                                draggable
                                                onDragFinish={({from, to}) =>
                                                    reorderList({from, to}, addedEvents, setAddedEvents)
                                                }
                                            >
                                                {x.title}
                                            </Cell>
                                        )
                                })
                            }
                        </FormItem>

                    </div>
                }



            </Group>

            <Group header={<Header mode={"secondary"}>Предпросмотр</Header>}>
                <Group header={
                    <div className="group-head">
                        <Header mode={"primary"}>{selectedCollection && selectedCollection.name}</Header>
                        <Header mode={"secondary"}><Link className={"look-all"}>Показать все</Link></Header>
                    </div>
                }>
                    {loadingPreview &&
                        <Loading/>
                    }
                    <EventsGallery>

                        {!loadingPreview && preview && preview.map((n, ko) => (
                            <EventCard
                                delete_={false}
                                key={ko}
                                disabled={true}
                                showType={true}
                                type={n.type}
                                img={n.photos && n.photos.length ? n.photos[0].path : ""}
                                title={n.title} description={n.description}/>
                        ))}
                    </EventsGallery>
                </Group>
            </Group>
            {snack}
        </Panel>
    )
}
export default CollectionsEdit