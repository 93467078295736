import React, {useEffect, useRef, useState} from "react"
import {
    Panel,PanelHeader,Div,Group,Header,Button,ScreenSpinner,Placeholder,Cell,ButtonGroup,IconButton
} from "@vkontakte/vkui";
import {useGlobalState} from "../../../components/States";
import { Icon24AddCircleOutline } from '@vkontakte/icons';
import AddCategory from "../../../alerts/AddCategory";
import Api from "../../../components/Api";
import { Icon56ErrorTriangleOutline } from '@vkontakte/icons';
import Error from "../../../alerts/Error";
import MySnack from "../../../alerts/MySnack";
import { Icon24DeleteOutline } from '@vkontakte/icons';
import { Icon24PenOutline } from '@vkontakte/icons';
import Confirm from "../../../alerts/Confirm";
import { Icon24ArrowUpRightOutline } from '@vkontakte/icons';
const Collections =({id,openCollection}) =>{
    const [popout,setPopout] = useGlobalState("popout");
    const [collections,setCollections] = useGlobalState("collections");
    const [snack,setSnack] = useState(null)
    const inpRef = useRef();


    const alert = (mess,err=false) =>{
        if(!err){
            setSnack(<MySnack mess={mess} setSnackbar={setSnack}/>);
        }else{
            setPopout(<Error closePopout={()=>setPopout(null)} mess={mess}/>)
        }
    }
    const createCategory = (name) =>{
        setPopout(<ScreenSpinner size={"large"}/>)
        Api.createCollection(name).then(r => {
            if(!r.result){
                alert(r.mess,true)
            }else{
                let cats = [...collections];
                cats.push({name,id:parseInt(r.id)})
                setCollections([...cats])
                alert("Подборка создана")
            }
        }).catch((r)=>{
            alert(r.message,true)
        });
    }
    const addCat = () =>{
        setPopout(<AddCategory inputRef={inpRef} closePopout={()=>setPopout(null)} fn={createCategory}/>)
    }
    const deleteCat = (id) =>{
        let cats = [...collections];
        let index = cats.findIndex(x=>parseInt(x.id) === parseInt(id))
        if(index >= 0){
            setPopout(<Confirm mess={`Удалить подборку - ${cats[index].name}?`} closePopout={()=>setPopout(null)} fn={()=>removeCat(id,index)}/>)
        }
    }
    const removeCat = (id,index) =>{
        let cats = [...collections];
        cats.splice(index,1);
        Api.removeCollections(id)
        setCollections([...cats])
    }
    const editCategory = (name,id) =>{
        let cats = [...collections];
        let index = cats.findIndex(x=>parseInt(x.id) === parseInt(id))
        Api.editCollections(name,null,null,parseInt(id))
        if(index >= 0){
            collections[index].name = name;
            setCollections([...cats])
        }
    }
    const editCat =(cat)=>{
        setPopout(<AddCategory is_category={false} edit_={true} cat={cat} inputRef={inpRef} closePopout={()=>setPopout(null)} fn={editCategory}/>)
    }
    const reorderList = ({ from, to }, list, updateListFn) => {
       /* const _list = [...list];
        _list[from].sort = to;
        _list[to].sort = from;
        Api.sortCat(_list[from].id,to)
        Api.sortCat(_list[to].id,from)
        console.log(_list)
        updateListFn(_list);*/
        from -= 1;
        to -= 1;
        const _list = [...list];
        _list.splice(from, 1);
        _list.splice(to, 0, list[from]);
        let map = _list.map((x,k)=>{
            if(x) {
                x.sort = k;
                return {id: x.id, sort: k}
            }
        })
        Api.sortCollections(map)
        updateListFn(_list);
    };
    return (
        <Panel id={id}>
            <PanelHeader>
               Подборки
            </PanelHeader>
            <Div className={"p-div"}>
                <Group  header={
                    <div className="div-header">
                        <Header mode={"secondary"}>Список</Header>
                        <Button onClick={addCat} before={<Icon24AddCircleOutline/>} mode={"primary"} appearance={"positive"}>
                            Добавить подборку
                        </Button>
                    </div>

                }>
                    {collections && collections.length ?
                        collections.sort((a,b)=>a.sort-b.sort).map((x,k)=>(
                            <Cell
                                draggable
                                onDragFinish={({ from, to }) =>
                                    reorderList({ from, to }, collections, setCollections)
                                }
                                onClick={()=>alert("F")}
                                 key={k}
                                after={
                                    <ButtonGroup mode="horizontal" gap="m" stretched>
                                        <IconButton onClick={()=>editCat(x.id)}>
                                            <Icon24PenOutline/>
                                        </IconButton>
                                        <IconButton onClick={()=>deleteCat(x.id)} >
                                            <Icon24DeleteOutline fill={"red"}/>
                                        </IconButton>
                                        <IconButton onClick={()=>openCollection(x)} >
                                            <Icon24ArrowUpRightOutline fill={"var(--vkui--color_text_positive)"}/>
                                        </IconButton>
                                    </ButtonGroup>
                                }
                            >{x.name}</Cell>
                        ))
                        :
                            <Placeholder icon={<Icon56ErrorTriangleOutline />}>
                                Тут пусто
                            </Placeholder>
                    }

                </Group>
            </Div>
            {snack}
        </Panel>
    )
}
export default Collections