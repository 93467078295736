import React, {useEffect, useState} from "react"
import {
    Alert,Input,FormItem
} from "@vkontakte/vkui"
import {useGlobalState} from "../components/States";

const AddCategory = ({fn,is_category = true,edit_=false,cat=null,inputRef,closePopout}) =>{
    const edit = edit_;
    const [categories,setCategories] = useGlobalState("categories");
    const [collections] = useGlobalState("collections");
    const [error,setError] = useState(false)
    const click = () =>{
        let val = inputRef.current.value;
        if(!val)
            setError(true)
        else {
            fn(val,cat)
            closePopout();
        }
    }
    useEffect(()=>{
        if(edit && cat >= 0){
            if(is_category){
                let category = categories.find(x=>parseInt(x.id) === parseInt(cat));
                if(category){
                    inputRef.current.value = category.name;
                }
            }else{
                let category = collections.find(x=>parseInt(x.id) === parseInt(cat));
                if(category){
                    inputRef.current.value = category.name;
                }
            }

        }
    },[])
    return (
        <Alert
            actions={[
                {
                    title: edit ? "Редактировать" : "Создать",
                    autoClose: false,
                    action: click
                },
                {
                    title: 'Отмена',
                    autoClose: true,
                    mode: 'cancel',
                },
            ]}
            actionsLayout="horizontal"
            onClose={closePopout}
            header={edit ? "Редактирование" : "Создание"}
        >
            <FormItem
                top="Имя"
                status={!error ? 'valid' : 'error'}

            >
                <Input onFocus={()=>setError(false)} getRef={inputRef}/>
            </FormItem>
        </Alert>
    )
}
export default AddCategory