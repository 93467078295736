import React, {useEffect, useRef, useState} from "react"
import {
    Panel,PanelHeader,Div,Group,Header,Button,ScreenSpinner,Placeholder,Cell,ButtonGroup,IconButton
} from "@vkontakte/vkui";
import {useGlobalState} from "../../components/States";
import { Icon24AddCircleOutline } from '@vkontakte/icons';
import AddCategory from "../../alerts/AddCategory";
import Api from "../../components/Api";
import { Icon56ErrorTriangleOutline } from '@vkontakte/icons';
import Error from "../../alerts/Error";
import MySnack from "../../alerts/MySnack";
import { Icon24DeleteOutline } from '@vkontakte/icons';
import { Icon24PenOutline } from '@vkontakte/icons';
import Confirm from "../../alerts/Confirm";
import CategoryItem from "../../components/CategoryItem";

const Categories =({id}) =>{
    const [categoriesType] = useGlobalState("categoriesType");
    const [popout,setPopout] = useGlobalState("popout");
    const [cat1,setCat1] = useState([])
    const [cat2,setCat2] = useState([])
    const [cat3,setCat3] = useState([])
    const [categories,setCategories] = useGlobalState("categories");
    const [snack,setSnack] = useState(null)
    const inpRef = useRef();
    useEffect(()=>{
        if(categories){
            setCat1(categories.filter(x=>x && parseInt(x.type)===0))
            setCat2(categories.filter(x=>x && parseInt(x.type)===1))
            setCat3(categories.filter(x=>x && parseInt(x.type)===2))
        }
    },[categories])
    useEffect(()=>{

    },[categoriesType])
    const alert = (mess,err=false) =>{
        if(!err){
            setSnack(<MySnack mess={mess} setSnackbar={setSnack}/>);
        }else{
            setPopout(<Error closePopout={()=>setPopout(null)} mess={mess}/>)
        }
    }
    const createCategory = (name) =>{
        setPopout(<ScreenSpinner size={"large"}/>)
        Api.createCategory(categoriesType, name).then(r => {
            if(!r.result){
                alert(r.mess,true)
            }else{
                let cats = [...categories];
                cats.push({name,type:categoriesType,id:parseInt(r.id)})
                setCategories([...cats])
                alert("Категория создана")
            }
        }).catch((r)=>{
            alert(r.message,true)
        });
    }
    const addCat = () =>{
        setPopout(<AddCategory inputRef={inpRef} closePopout={()=>setPopout(null)} fn={createCategory}/>)
    }
    const deleteCat = (id) =>{
        let cats = [...categories];
        let index = cats.findIndex(x=>parseInt(x.id) === parseInt(id))
        if(index >= 0){
            setPopout(<Confirm mess={`Удалить категорию - ${cats[index].name}?`} closePopout={()=>setPopout(null)} fn={()=>removeCat(id,index)}/>)
        }
    }
    const removeCat = (id,index) =>{
        let cats = [...categories];
        cats.splice(index,1);
        Api.removeCategory(id)
        setCategories([...cats])
    }
    const editCategory = (name,id) =>{
        let cats = [...categories];
        let index = cats.findIndex(x=>parseInt(x.id) === parseInt(id))
        if(index >= 0){
            cats[index].name = name;
            setCategories([...cats])
            Api.editCategory(name,id)
        }
    }
    const editCat =(cat)=>{
        setPopout(<AddCategory edit_={true} cat={cat} inputRef={inpRef} closePopout={()=>setPopout(null)} fn={editCategory}/>)
    }
    const reorderList = ({ from, to }, list, updateListFn) => {
       /* const _list = [...list];
        _list[from].sort = to;
        _list[to].sort = from;
        Api.sortCat(_list[from].id,to)
        Api.sortCat(_list[to].id,from)
        console.log(_list)
        updateListFn(_list);*/
        from -= 1;
        to -= 1;
        const _list = [...list];
        _list.splice(from, 1);
        _list.splice(to, 0, list[from]);
        let map = _list.map((x,k)=>{
            if(x) {
                x.sort = k;
                return {id: x.id, sort: k}
            }
        })
        Api.sortCat(map)
        updateListFn(_list);
    };
    const changeType = (cat_id,type) =>{
        let cats = [...categories];
        let index = cats.findIndex(x=>parseInt(x.id) === parseInt(cat_id))
        if(index >= 0){
            cats[index].menu_type = type;
            setCategories([...cats])
        }
    }
    return (
        <Panel id={id}>
            <PanelHeader>
                Категории {categoriesType === 2 ? "мест" : categoriesType+1}
            </PanelHeader>
            <Div className={"p-div"}>
                <Group  header={
                    <div className="div-header">
                        <Header mode={"secondary"}>Список</Header>
                        <Button onClick={addCat} before={<Icon24AddCircleOutline/>} mode={"primary"} appearance={"positive"}>
                            Добавить категорию
                        </Button>
                    </div>

                }>
                    {categoriesType === 0 && cat1.length ?
                        cat1.sort((a,b)=>a.sort-b.sort).map((x,k)=>(
                            <CategoryItem changeTypeParent={changeType} x={x} cat1={cat1} reorderList={reorderList} k={k} deleteCat={deleteCat} editCat={editCat} setCat1={setCat1}/>
                        ))
                        :categoriesType === 0 ?
                            <Placeholder icon={<Icon56ErrorTriangleOutline />}>
                                Тут пусто
                            </Placeholder> : ""
                    }
                    {categoriesType === 1 && cat1.length ?
                        cat2.sort((a,b)=>a.sort-b.sort).map((x,k)=>(
                            <CategoryItem changeTypeParent={changeType} x={x} cat1={cat2} reorderList={reorderList} k={k} deleteCat={deleteCat} editCat={editCat} setCat1={setCat2}/>
                        ))
                        :categoriesType === 1 ?
                            <Placeholder icon={<Icon56ErrorTriangleOutline />}>
                                Тут пусто
                            </Placeholder> : ""
                    }
                    {categoriesType === 2 && cat1.length ?
                        cat3.sort((a,b)=>a.sort-b.sort).map((x,k)=>(
                            <CategoryItem changeTypeParent={changeType} x={x} cat1={cat3} reorderList={reorderList} k={k} deleteCat={deleteCat} editCat={editCat} setCat1={setCat3}/>
                        ))
                        :categoriesType === 2 ?
                        <Placeholder icon={<Icon56ErrorTriangleOutline />}>
                            Тут пусто
                        </Placeholder> : ""
                    }

                </Group>
            </Div>
            {snack}
        </Panel>
    )
}
export default Categories