import React, {useEffect, useState} from "react"
import {Title,Subhead,Cell,Button,SimpleCell,Switch } from "@vkontakte/vkui";
import Api from "../Api";
import {getImage} from "../CONSTS";

const EventCard = ({title,deleteEvent,delete_=true,onClick,enableMe,disabled=false,enableBox=false,enable,img,description,showType=false,type=""}) =>{
    const [image,setImage] = useState(null)
    const [style,setStyle] = useState(false)
    useEffect(()=>{
        if(img){
            let url = getImage(img);
            const img_ = new Image();
            img_.onload = function() {
                if(this.width <= this.height){
                    setStyle(true)
                }
                setImage(url)
                //alert(this.width + 'x' + this.height);
            }
            img_.onerror = function (){
                setImage("https://upload.wikimedia.org/wikipedia/commons/9/9a/%D0%9D%D0%B5%D1%82_%D1%84%D0%BE%D1%82%D0%BE.png")
            }

            img_.src = url;
        }else{
            setImage("https://upload.wikimedia.org/wikipedia/commons/9/9a/%D0%9D%D0%B5%D1%82_%D1%84%D0%BE%D1%82%D0%BE.png")
        }
    },[img])
    return (
        <div className={"event-card"}>
            {showType &&
                <span className="card-type">
                {type}
            </span>
            }

            {enableBox &&
                <div className="event-card__enable">
                    <SimpleCell Component="label" after={<Switch onClick={enableMe} checked={enable} />}>
                        Опубликована
                    </SimpleCell>
                </div>
            }
            <Cell onClick={onClick}  disabled={disabled}>
                {image === null &&
                    <span className="image-load"></span>
                }
                {image !== null &&
                    <img src={image} style={{objectFit:style ? "contain" : "cover"}} className={"event-card__img"}/>
                }
                <Title className={"event-card__title"} level={"3"}>{title}</Title>
                <Subhead >{description ? description : <i>Описания нет</i>}</Subhead>

            </Cell >
            {delete_ &&
                <Button onClick={deleteEvent} style={{marginTop:10}} stretched appearance={"negative"}>
                    Удалить
                </Button>
            }

        </div>

    )
}
export default EventCard