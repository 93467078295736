import React, {useEffect, useRef, useState} from "react"
import {
    Panel,CustomSelectOption, PanelHeader,File, PanelHeaderBack, Group, Header, FormItem, Checkbox, Input,Textarea, Button, ScreenSpinner, Div,CustomSelect
} from "@vkontakte/vkui";
import {Icon24Document} from "@vkontakte/icons"
import {useGlobalState} from "../../../components/States";
import MyDateInput from "../../../components/MyDateInput";
import MySnack from "../../../alerts/MySnack";
import TimePicker from 'react-time-picker';
import Api from "../../../components/Api";
import FilesUpload from "../../../alerts/FilesUpload";
import List from "../../../components/Photos/List";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {escapeHtml} from "../../../components/CONSTS";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const EventEdit = ({id, go, selectedEvent, setSelectedEvent}) => {
    const [settings, setSettings] = useGlobalState("settings")
    const [popout, setPopout] = useGlobalState("popout");
    const [places, setPlaces] = useGlobalState("places");
    const [querySelect, setQuerySelect] = useState('');
    const [events, setEvents] = useGlobalState("events");
    const [categories, setCategories] = useGlobalState("categories");
    const [snack, setSnack] = useState(null)
    const [photos,setPhotos] = useState(null)
    const [map, setMap] = useState("")
    const [data, setData] = useState({
        title:selectedEvent.title,
        str_place_name:selectedEvent.str_place_name,
        sub_title:selectedEvent.sub_title,
        adres:selectedEvent.adres,
        time:selectedEvent.time,
        description:selectedEvent.description,
        place_id:selectedEvent.place_id ? selectedEvent.place_id : -1,
        sites:selectedEvent.sites,
        cost:selectedEvent.cost,
    })

    const [date, setDate] = useState()
    const [dateEnd, setDateEnd] = useState()

    useEffect(()=>{
        if(selectedEvent && selectedEvent.when && selectedEvent.when !== "0000-00-00") {
            setDate(new Date(selectedEvent.when))
        }
        if(selectedEvent && selectedEvent.when_end && selectedEvent.when_end !== "0000-00-00") {
            setDateEnd(new Date(selectedEvent.when_end))
        }
        if (selectedEvent && selectedEvent.map) {
            setMap(JSON.parse(selectedEvent.map))
        }
        if(selectedEvent && selectedEvent.photos && Array.isArray(selectedEvent.photos)){
            setPhotos(selectedEvent.photos)
        }
    },[])
    const changeCheckBox = e => {
        let id = parseInt(e.currentTarget.dataset.id);
        let val = e.currentTarget.checked;
        Api.setCategoryEvent(id,val,selectedEvent.id);
    }
    const changeInput = e =>{
        let val =  e.currentTarget.value;
        let col = e.currentTarget.dataset.col;
        if(!col)
            col = "place_id"
        let d = {...data};
        d[col] = val;
        setData({...d})
    }
    const defChecked = e => {
        if(selectedEvent && selectedEvent.categories){
            return selectedEvent.categories.find(x=>parseInt(x.category_id) === parseInt(e))
        }
        return false;

    }
    const save = () =>{
        if(!data.title){
            setSnack(<MySnack setSnackbar={setSnack} mess={"Введите имя мероприятия"} error={true}/>)
            return;
        }
        setPopout(<ScreenSpinner size={"large"}/>)
        let d = {...data};
        let xDate = new Date(date);
        let xMonth = xDate.getMonth()+1;
        let xDay = String(xDate.getDate()).length === 1 ? "0"+xDate.getDate() : xDate.getDate();
        xMonth = String(xMonth).length === 1 ? "0"+xMonth : xMonth;
        d.when = date ? `${xDate.getFullYear()}-${xMonth}-${xDay} ${xDate.getHours()}:${xDate.getMinutes()}:00.0` : "";

        let xDateEnd = new Date(dateEnd);
        xMonth = xDateEnd.getMonth()+1;
        xDay = String(xDateEnd.getDate()).length === 1 ? "0"+xDateEnd.getDate() : xDateEnd.getDate();
        xMonth = String(xMonth).length === 1 ? "0"+xMonth : xMonth;

        d.when_end = dateEnd ? `${xDateEnd.getFullYear()}-${xMonth}-${xDay} ${xDateEnd.getHours()}:${xDateEnd.getMinutes()}:00.0` : "";


        d.map = map ? JSON.stringify(map) : ""
        Api.editEvents(d,selectedEvent.id).then((r)=>{
            if(!r.result){
                setSnack(<MySnack setSnackbar={setSnack} mess={r.mess} error={true}/>)
            }else{
                let s  = {...selectedEvent,...data};
                let index = events.findIndex(x=>parseInt(x.id) === parseInt(selectedEvent.id));
                setSelectedEvent(s);
                let all_s = [...events];
                all_s[index] = {...s};
                setEvents(all_s);
                setSnack(<MySnack setSnackbar={setSnack} mess={"Данные сохранены"}/>)
            }
            setPopout(null)
        }).catch(r=>{
            setPopout(null)
            alert(r.message)
        })
    }
    const filesWasUpload = e =>{
        let ph = Array.isArray(photos) ? [...photos] : [];
        ph.push(...e)
        setPhotos([...ph])
        setPopout(null)
    }
    const uploadFiles = (e) =>{
        setPopout(<FilesUpload
            fn={filesWasUpload}
            project_type={"event"}
            project_id={selectedEvent.id}
                files={e.currentTarget.files}
               closePopout={()=>setPopout(null)} />)
    }
    const getPlaces = () =>{
        let p = [];
        p.push({
            label:"Не выбрано",
            value:-1
        })
        places.map(x=>{
            p.push({
                label:escapeHtml(x.title),
                value:x.id
            })
        })
        return p;
    }
    const changeMap = (e) =>{
        if(!Array.isArray(e)){
            let c = e.currentTarget.value.split(",");
            if(c.length === 2)
                setMap(c)
            else{
                setSnack(<MySnack setSnackbar={setSnack} error={true} mess={"Нужны координаты вида 45.33223,43.3211"}/>)
            }
        }else{
            setMap(e)
        }
    }
    /*const changePlaceName =e =>{
        let val = e.currentTarget.value;
        if(!val){
            let d = {...data};
            d["place_id"] = null;
            setData({...d})
        }else{
            let place = places.find(x=>escapeHtml(x.title.toLowerCase()).includes(val.toLowerCase()))
            let d = {...data};
            if(place){
                d["place_id"] = place.id;
            }else{
                d["place_id"] = null;
            }
            console.log(d)
            setData({...d})
        }
        changeInput(e)
    }*/
    const removePhoto = id =>{
        let ph = [...photos];
        let events_ = [...events];
        let index = ph.findIndex(x=>x.id === id);
        if(index >= 0){
            ph.splice(index,1)
            setPhotos([...ph]);
            let eventIndex = events_.findIndex(x=>x.id === selectedEvent.id);
            events_[eventIndex].photos = [...ph];
            setEvents(events_);
        }
    }
    return (
        <Panel id={id}>
            <PanelHeader
                before={<PanelHeaderBack onClick={go} data-to={"home"}/>}
            >
                Мероприятие - {selectedEvent ? selectedEvent.title : ""}
            </PanelHeader>
            <Group className={"div-f"} header={<Header mode={"secondary"}>Настройки мероприятия</Header>}>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Название"}>
                        <Input defaultValue={data.title} onChange={changeInput} data-col={"title"}/>
                    </FormItem>
                    <FormItem top={"Подзаголовок"}>
                        <Input defaultValue={data.sub_title} onChange={changeInput} data-col={"sub_title"}/>
                    </FormItem>
                    <FormItem top={"Адрес"}>
                        <Input defaultValue={data.adres} onChange={changeInput} data-col={"adres"}/>
                    </FormItem>
                    <FormItem top={"Время"}>
                        <TimePicker
                            format={"HH:mm"}
                            disableClock={true}
                            onChange={(v)=>changeInput(
                                {currentTarget:{value:v ? v : "",dataset:{col:"time"}}}
                            )} value={data.time} />
                    </FormItem>
                    <FormItem top={"Когда"}>
                        <MyDateInput enableTime={true} time={true} setValue={setDate} value={date} />
                    </FormItem>
                    <FormItem top={"Когда кончается"}>
                        <MyDateInput enableTime={true} time={true} setValue={setDateEnd} value={dateEnd} />
                    </FormItem>
                </div>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Сайты (через запятую)"}>
                        <Textarea defaultValue={data.sites} onChange={changeInput} data-col={"sites"}/>
                    </FormItem>
                    <FormItem top={"Описание"}>
                        <Textarea defaultValue={data.description} onChange={changeInput} data-col={"description"}/>
                    </FormItem>
                </div>
                <div className="div-row div-row__stretched">
                    <FormItem top={"Место"}>
                        {places &&
                            <CustomSelect onChange={changeInput} value={data.place_id}  className={"custom-select-my"} placeholder="Введите название" searchable
                                          options={getPlaces()}
                            />
                        }
                    </FormItem>
                    <FormItem top={"Место"}>
                        <Input value={data.str_place_name} onChange={changeInput} data-col={"str_place_name"}/>
                    </FormItem>
                    <FormItem top={"Стоимость"}>
                        <Input defaultValue={data.cost} onChange={changeInput} data-col={"cost"}/>
                    </FormItem>
                    <FormItem top={"Карта (Координаты, или выберите на карте ниже)"}>
                        <Input value={map} onChange={changeMap} data-col={"map"}/>
                    </FormItem>
                </div>

                <FormItem>
                    <Button onClick={save} size={"m"} stretched appearance={"positive"}>Сохранить</Button>
                </FormItem>





                <FormItem top={"Категории 1"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 0).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>
                <FormItem top={"Категории 2"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 1).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>
                <FormItem top={"Категории мест"}>
                    <div className="div-row">
                        {categories.filter(x => x.type === 2).map((x, key) => {
                            let ch = defChecked(x.id);
                            return (<Checkbox key={key} defaultChecked={ch} onChange={changeCheckBox}
                                              data-id={x.id}>{x.name}</Checkbox>)
                        })}
                    </div>
                </FormItem>

            </Group>
            <Group  header={<Header mode={"secondary"}>Фотографии мероприятия</Header>}>
                <FormItem top="Загрузите документы">
                    <File multiple onInput={uploadFiles} before={<Icon24Document role="presentation" />} size="l" mode="secondary" />
                </FormItem>
                <Div>
                    {Array.isArray(photos) && !photos.length &&
                        <span>Фотографий нет</span>
                    }
                    {!Array.isArray(photos) &&
                        <span>Фотографии не загружены</span>
                    }
                    {Array.isArray(photos) && photos.length > 0 &&
                        <List removePhoto={removePhoto} setImages={setPhotos} images={photos}/>
                    }
                </Div>
            </Group>
            <Group header={<Header mode={"secondary"}>Карта места</Header>}>
                <YMaps>
                    <Div>
                        <Map
                            width={"100%"} height={600} defaultState={{
                            center: data.map ? data.map :(settings ? settings.map_center.split(",") :[55.75, 37.57]),
                            zoom: settings ? settings.map_zoom :9
                        }} >
                            <Placemark
                                instanceRef={e=> {
                                    if(e){
                                        e.events.add('dragend', function (d) {
                                            changeMap(e.geometry._coordinates)
                                        });
                                    }

                                }}
                                options={{draggable:true}} geometry={map ? map : (settings ? settings.map_center.split(",") :[55.75, 37.57])} />
                        </Map>
                    </Div>
                </YMaps>
            </Group>
            {snack}
        </Panel>
    )
}
export default EventEdit