import React from "react"
import {ButtonGroup, Cell, IconButton,FormItem,RadioGroup,Radio,FormLayout} from "@vkontakte/vkui";
import {Icon24DeleteOutline, Icon24PenOutline} from "@vkontakte/icons";
import api from "./Api";


const CategoryItem = ({x,changeTypeParent,reorderList,k,cat1,setCat1,editCat,deleteCat}) =>{
    const changeType = e=>{
        api.changeType(x.id,parseInt(e.target.value))
        changeTypeParent(x.id,parseInt(e.target.value))
    }
    return (
        <Cell
            draggable
            onDragFinish={({ from, to }) =>
                reorderList({ from, to }, cat1, setCat1)
            }
            disabled key={k}
            after={
                <ButtonGroup mode="horizontal" gap="m" stretched>
                    <FormLayout>
                    <FormItem top="Принадлежность">
                        <RadioGroup onChange={changeType} mode="horizontal">
                            <Radio defaultChecked={x.menu_type == 0} name="size" value={0}>
                                (сверху, где - бесплатно)
                            </Radio>
                            <Radio defaultChecked={x.menu_type == 1} name="size" value="1">
                                Где
                            </Radio>
                            <Radio defaultChecked={x.menu_type == 2} name="size" value="2">
                                Кому
                            </Radio>
                            <Radio defaultChecked={x.menu_type == 3} name="size" value="3">
                                Что
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    </FormLayout>
                    <IconButton onClick={()=>editCat(x.id)}>
                        <Icon24PenOutline/>
                    </IconButton>
                    <IconButton onClick={()=>deleteCat(x.id)} >
                        <Icon24DeleteOutline fill={"red"}/>
                    </IconButton>

                </ButtonGroup>
            }
        >{x.id} - {x.name}</Cell>
    )
}
export default CategoryItem