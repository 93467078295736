import React, {useState} from "react"
import {Gallery} from "@vkontakte/vkui";


const EventsGallery = ({ children }) =>{
    const [slideIndex, setSlideIndex] = useState(0);
    return (
        <Gallery
            slideWidth="20%"
            align="left"
            className={"events"}
            slideIndex={slideIndex}
            onChange={setSlideIndex}
            isDraggable={true}
            showArrows={true}
        >
            {children}
        </Gallery>
    )
}
export default EventsGallery