import React, {useEffect, useRef, useState} from "react"
import {
    Div, Group,
    Header, SplitLayout, SplitCol, View,
    Panel, PanelHeader, Spinner, Input, FormItem, Button, SegmentedControl
} from "@vkontakte/vkui";
import {Map, Placemark, YMaps} from "react-yandex-maps";
import api from "../../../components/Api";
import MySnack from "../../../alerts/MySnack";
import DataTable from 'react-data-table-component';
import {useGlobalState} from "../../../components/States";
import MyDateRange from "../../../components/MyDateRange";
import AnalythModal from "./AnalythModal";

const Analyth = ({id}) => {
    const [analyth, setAnalyth] = useGlobalState("analyth")
    const [filterCards, setFilterCards] = useState(null)
    const [snack, setSnack] = useState(null)
    const [date, setDate] = useState(null)
    const [modal, setModal] = useState(null)
    const [dataTableInfo, setDataTableInfo] = useState({columns: null, data: null})
    const [allSorted, setAllSorted] = useState(null)
    const [allSortedSearch, setAllSortedSearch] = useState(null)

    const [what, setWhat] = useState(0)

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


    let groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const sortCardsGender = (a, b, type) => {
        let int = type === "m" ? 2 : 1;
        let allA = a.length;
        let allB = b.length;
        let mA = a.filter(x => x.sex === int).length;
        let mB = b.filter(x => x.sex === int).length;
        let perA = Math.ceil((mA / allA) * 100);
        let perB = Math.ceil((mB / allB) * 100);
        return perA - perB;
    }
    const sortCardsYeard = (a, b) => {

        let groupUsersA = (groupBy(a, "user_id"))
        groupUsersA = Object.keys(groupUsersA).map(x => groupUsersA[x][0].years)
        let sA = groupUsersA.reduce((partialSum, c) => partialSum + c || 0, 0) / Object.keys(groupUsersA).length;
        let groupUsersB = (groupBy(b, "user_id"))
        groupUsersB = Object.keys(groupUsersB).map(x => groupUsersB[x][0].years)
        let sB = groupUsersB.reduce((partialSum, c) => partialSum + c || 0, 0) / Object.keys(groupUsersB).length;

        return sA - sB;
    }
    useEffect(() => {
        if (analyth && analyth.length) {
            let events = analyth.filter(x => x.card_type === "ev");
            let places = analyth.filter(x => x.card_type !== "ev");
            let group_events = groupBy(events, "card_id")
            let group_places = groupBy(places, "card_id")
            setAllSorted({events: group_events, places: group_places})
            setDataTableInfo({
                columns: [
                    {
                        name: "ID",
                        cell: (row, index) => index + 1
                    },
                    {
                        name: 'Мероприятие',
                        selector: (row) => {
                            if (row.length && row[0].card_title)
                                return row[0].card_title
                            else return `Неизвестно. id - ${row[0].card_id}`
                        },
                    },
                    {
                        name: 'Кол-во открытий',
                        sortable: true,
                        selector: row => row.length,
                    },
                    {
                        name: '% муж',
                        sortable: true,
                        sortFunction: (a, b) => sortCardsGender(a, b, "m"),
                        cell: (row) => {
                            let all = row.length;
                            console.log(row)
                            let m = row.filter(x => x.sex === 2).length;
                            return Math.ceil((m / all) * 100) + "%";
                        },
                    },
                    {
                        name: '% жен',
                        sortable: true,
                        sortFunction: (a, b) => sortCardsGender(a, b, "w"),
                        cell: (row) => {
                            let all = row.length;
                            let w = row.filter(x => x.sex === 1).length;
                            return Math.ceil((w / all) * 100) + "%";
                        },
                    },
                    {
                        name: 'Средний возраст',
                        sortable: true,
                        sortFunction: (a, b) => sortCardsYeard(a, b),
                        cell: (row) => {
                            let groupUsers = (groupBy(row, "user_id"))
                            groupUsers = Object.keys(groupUsers).map(x => groupUsers[x][0].years)

                            let s = groupUsers.reduce((partialSum, c) => partialSum + c || 0, 0) / Object.keys(groupUsers).length;
                            return s;
                        },
                    },
                ],
                data: Object.keys(group_events).map(x => group_events[x])
            })
        }
    }, [analyth])

    useEffect(() => {
        if(!allSorted)
            return;
        if (filterText) {
            let all = {...allSorted};


            let data = {
                events: Object.keys(all.events).filter(x => all.events[x][0].card_title && all.events[x][0].card_title.toLowerCase().includes(filterText.toLowerCase())),
                places: Object.keys(all.places).filter(x => all.places[x][0].card_title && all.places[x][0].card_title.toLowerCase().includes(filterText.toLowerCase()))
            }
            if (what === 0)
                setTableData(data.events.map(x => all.events[x]))
            else
                setTableData( data.places.map(x => all.places[x]))

        } else {
            if (what === 0)
                setTableData(Object.keys(allSorted.events).map(x => allSorted.events[x]))
            else
                setTableData(Object.keys(allSorted.places).map(x => allSorted.places[x]));

        }
    }, [filterText,allSorted,what])

    const setTableData = data => {
        setDataTableInfo(prevState => {
            return {
                ...prevState,
                data: data
            }
        })
    }

    const selectCard = e => {

    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FormItem top={"Поиск по названию"}>
                <Input onChange={e => setFilterText(e.currentTarget.value)} onClear={handleClear} value={filterText}/>
            </FormItem>
        );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {

    }, [])
    const manCount = (row,sex) =>{
        let all = row.length;
        let m = row.filter(x => x.sex === sex).length;
        return Math.ceil((m / all) * 100) + "%";
    }
    const getYears = row =>{
        let groupUsers = (groupBy(row, "user_id"))
        groupUsers = Object.keys(groupUsers).map(x => groupUsers[x][0].years)

        let s = groupUsers.reduce((partialSum, c) => partialSum + c || 0, 0) / Object.keys(groupUsers).length;
        return s;
    }
    function openInNewTab(url) {
        window.open(url, '_blank').focus();
    }
    const getExcel = e =>{
        let arr = [];
        dataTableInfo.data.map((row,index)=>{
            arr.push({
                name: row.length && row[0].card_title ? row[0].card_title : `Название неизвестно. id - ${row[0].card_id}`,
                open_count: row.length,
                man: manCount(row,2),
                woman: manCount(row,1),
                years: getYears(row)
            })
        })
        api.excelAnalyth(arr).then(r=>{
            if(!r.result){
                setSnack(<MySnack error={true} setSnackbar={setSnack} mess={r.mess}/>)
            }else{
                openInNewTab("https://pg36.ru/api/Controller/Api/an.xlsx")
            }
        });
    }


    if (!analyth)
        return <Spinner size={"large"}/>
    else if (!analyth.length)
        return "Аналитика пуста";
    return (
        <SplitLayout
            modal={<AnalythModal setModal={setModal} activeModal={modal} cards={filterCards} selectCard={selectCard}/>}>
            <SplitCol>
                <View activePanel={id}>
                    <Panel id={id}>
                        <PanelHeader>
                            Аналитика
                        </PanelHeader>
                        <Group>
                            <Div style={{width: "100%"}}>
                                <SegmentedControl
                                    onChange={(e) => {
                                        setWhat(e)
                                    }}
                                    value={what}
                                    options={[
                                        {
                                            'label': `Мероприятия`,
                                            'value': 0,
                                            'aria-label': 'Плитки',
                                        },
                                        {
                                            'label': `Места`,
                                            'value': 1,
                                            'aria-label': 'Список',
                                        },

                                    ]}
                                />
                            </Div>
                        </Group>
                        {/*<Group header={<Header>Фильтры</Header>}>

                        </Group>*/}
                        <Group header={<Button onClick={getExcel}>Выгрузить в Excel</Button>}>
                            {dataTableInfo && dataTableInfo.columns && dataTableInfo.data &&
                                <DataTable
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    pagination
                                    columns={dataTableInfo.columns}
                                    data={dataTableInfo.data}
                                />
                            }
                        </Group>
                        {snack}
                    </Panel>
                </View>
            </SplitCol>
        </SplitLayout>
    )
}
export default Analyth