import React from "react"
import {LocaleProvider,DateRangeInput} from "@vkontakte/vkui";

const MyDateRange =({setValue,value}) =>{
    return(
        <div style={{ display: 'flex' }}>
            <LocaleProvider value={"ru"}>
                <DateRangeInput
                    value={value}
                    onChange={setValue}
                    disablePast={true}
                    disableFuture={false}
                    closeOnChange={false}
                    disablePickers={false}
                    disableCalendar={false}
                />
            </LocaleProvider>
        </div>
    )
}
export default MyDateRange