export const SITE_URL = "https://pg36.ru/api";
export const getImage = (path) => `${SITE_URL}/images/get?i=${path}`
export const prependToArr = (value, array) => {
    let newArray = array.slice();
    newArray.unshift(value);
    return newArray;
}
export const escapeHtml = (text)=> {
    let map =
        {
            '&amp;': '&',
            '&lt;': '<',
            '&gt;': '>',
            '&quot;': '"',
            '&#039;': "'"
        };
    return text.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function(m) {return map[m];});
}