import { createGlobalState } from 'react-hooks-global-state';
const { setGlobalState, useGlobalState } = createGlobalState({
    categoriesType:0,
    popout:null,
    settings:null,
    analyth:null,
    categories:[],
    collections:[],
    events:[],
    places:[],
});
export {useGlobalState,setGlobalState}