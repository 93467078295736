import React, {useState} from 'react';
import {Panel, Div,Input,FormItem,Title,Button,Footnote} from '@vkontakte/vkui';
import Api from "../components/Api";
import Cookies from 'universal-cookie';


const Auth = ({id,getAll}) => {
    const [error,setError] = useState("")
    const [email,setEmail] = useState("admin@gmail.com")
    const [password,setPassword] = useState("")
    const [loading,setLoading] = useState(false)
    const logIn = () =>{
        setLoading(true)
        Api.logIn(email,password).then((r)=>{
            if(!r.data.result)
                setError(r.data.mess)
            else{
                let headers = r.headers;
                console.log("AUTH",headers)
                getAll();
            }
            setLoading(false)
        })
    }
    return (
        <Panel id={id}>
            <Div className={"div-center"}>
                <Title level={"2"}>
                    Авторизация
                </Title>
                <FormItem top={"Email"}>
                    <Input onFocus={()=>setError("")} value={email} onChange={e=>setEmail(e.currentTarget.value)} type={"email"}/>
                </FormItem>
                <FormItem top={"Пароль"}>
                    <Input  onFocus={()=>setError("")} value={password} onChange={e=>setPassword(e.currentTarget.value)} type={"password"}/>
                </FormItem>
                {error &&
                    <Footnote caps style={{color:"var(--vkui--color_text_negative)",marginBottom:10}}>{error}</Footnote>
                }
                <Button onClick={logIn} loading={loading} appearance={"positive"} size={"m"}>
                    Войти
                </Button>
            </Div>
        </Panel>
    )
};


export default Auth;
