import React, {useEffect, useState} from "react"
import {
    Panel,PanelHeader,Group,Spinner,Header,SimpleCell,Avatar,Pagination,FormItem
} from "@vkontakte/vkui";
import api from "../../../components/Api";
import {ChartForGender} from "./UsersCharts";

const Users =({id}) =>{
    const [users,setUsers] = useState(null)
    const [activeUsers,setActiveUsers] = useState(null)
    const [totalPages,setTotalPages] = useState(null)
    let limit = 20;
    const [page,setPage] = useState(1);
    useEffect(()=>{
        api.getAllUsers().then(r=>{
            if(r.data.result){
                let us = r.data.users;
                setTotalPages( us.length >= limit ? Math.ceil(us.length / limit) : 1)
                setUsers(r.data.users)
                setActiveUsers(r.data.users.slice(0,limit))
            }
        })
    },[])
    const changePage = p =>{
        if(p<= 0)
            return;
        let pa = p-1;
        let u = [...users];
        let start = pa*limit;
        let spliced = u.slice(start,start+limit);
        setActiveUsers(spliced);
        setPage(p)
    }
    if(!activeUsers || !users)
        return <Spinner size={"large"}/>
    return (
        <Panel id={id}>
            <PanelHeader>
                Пользователи
            </PanelHeader>
            <Group header={<Header>Все пользователи</Header>}>
                <div className={"users-div"}>
                    {activeUsers.map((x,k)=>(
                        <div key={k} className="user">
                            <SimpleCell href={`//vk.com/id${x.id}`} before={<Avatar size={40} src={x.photo} />}>
                                {x.name}
                            </SimpleCell>
                        </div>
                    ))}

                </div>
                <div className={"users-pagination"}>
                    <Pagination
                        currentPage={page}
                        siblingCount={1}
                        boundaryCount={1}
                        totalPages={totalPages}
                        onChange={changePage}
                    />
                </div>
            </Group>
            <Group header={<Header mode={"secondary"}>Статистика</Header>}>
                <div className="double-col">
                    <div className="double-col__col div-center" style={{height:"auto"}}>
                        <FormItem top={"Пол"}>
                            <ChartForGender style={{maxWidth:300}} genders={[users.filter(x=>x.sex === 1 || x.sex === 0).length,users.filter(x=>x.sex === 2).length]}/>

                        </FormItem>
                    </div>
                    {/*<div className="double-col__col div-center" style={{height:"auto"}}>
                        <FormItem top={"Новые пользователи"}>
                            <ChartForGender style={{maxWidth:300}} genders={[users.filter(x=>x.sex === 1 || x.sex === 0).length,users.filter(x=>x.sex === 2).length]}/>

                        </FormItem>
                    </div>*/}
                </div>



            </Group>
        </Panel>
    )
}
export default Users