import React from "react"
import {Icon28CheckCircleOutline,Icon28ErrorCircleOutline} from "@vkontakte/icons"
import {Snackbar} from "@vkontakte/vkui"

const MySnack = ({error=false,mess,setSnackbar}) =>{
    return error ?
        <Snackbar
            onClose={() => setSnackbar(null)}
            before={<Icon28ErrorCircleOutline fill="var(--vkui--color_icon_negative)" />}
        >
            {mess}
        </Snackbar>
        :
        <Snackbar
            onClose={() => setSnackbar(null)}
            before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
        >
            {mess}
        </Snackbar>
}
export default MySnack