import React from "react"
import {
    ModalRoot,
    ModalPage,
    ModalPageHeader,
    Div
} from "@vkontakte/vkui";

const AnalythModal = ({activeModal,setModal,cards,selectCard}) =>{
    const back = () =>{
        setModal(null)
    }
    return (
        <ModalRoot activeModal={activeModal} onClose={back}>
            <ModalPage
                id={"cards"}
                onClose={()=>{

                }}
                settlingHeight={100}
                he
                header={
                    <ModalPageHeader>
                        Карточки
                    </ModalPageHeader>
                }
            >
                <Div>

                </Div>
            </ModalPage>
        </ModalRoot>
    )
}
export default AnalythModal