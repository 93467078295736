import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Panel, View, Header, Button, Group, Cell, Div, Avatar} from '@vkontakte/vkui';
import Menu from "../components/Menu";
import Categories from "./SubPanels/Categories";
import Collections from "./SubPanels/Collections/Collections";
import Events from "./SubPanels/Events/Events";
import Places from "./SubPanels/Places/Places";
import Settings from "./SubPanels/Settings/Settings";
import Users from "./SubPanels/Users/Users";
import SortEvents from "./SubPanels/sortEvents/SortEvents";
import SortPlaces from "./SubPanels/sortPlaces/SortPlaces";
import Analyth from "./SubPanels/Analyth/Analyth";

const Home = ({id,goToMain,selectEvent,selectPlace, go,setPopout,openCollection, activePanel}) => {

    return (
        <Panel id={id}>
            <div className="container">
                <Menu activePanel={activePanel} go={go}/>
                <div className="content">
                    <View activePanel={activePanel}>
                        <Users id={"users"}/>
                        <SortEvents id={"sort-meroprs"}/>
                        <SortPlaces id={"sort-places"}/>
                        <Categories setPopout={setPopout} id={"categories"}/>
                        <Analyth setPopout={setPopout} id={"analyth"}/>
                        <Collections openCollection={openCollection} setPopout={setPopout} id={"collections"}/>
                        <Events selectEvent={selectEvent} go={go} id={"events"}/>
                        <Places go={go} selectPlace={selectPlace} id={"places"}/>
                        <Settings go={go} id={"settings"}/>
                    </View>
                </div>
            </div>
        </Panel>
    )
};


export default Home;
