import React from "react"
import {ButtonGroup, Cell, IconButton} from "@vkontakte/vkui";
import {Icon24ArrowUpRightOutline, Icon24DeleteOutline, Icon24PenOutline} from "@vkontakte/icons";

const PhotoCardEdit = ({url,deleteImg,reorderList}) =>{
    return (
        <Cell
            className={"photo photos-list__photo"}
            draggable
            onDragFinish={reorderList}
            after={
                <ButtonGroup mode="horizontal" gap="m" stretched>
                    <IconButton onClick={deleteImg} >
                        <Icon24DeleteOutline fill={"red"}/>
                    </IconButton>
                </ButtonGroup>
            }
        >
            <img src={url} alt=""/>
        </Cell>
    )
}
export default PhotoCardEdit