import React from "react"
import {LocaleProvider,DateInput} from "@vkontakte/vkui";

const MyDateInput =({setValue,enableTime = true,time = false,value}) =>{
    return(
        <div style={{ display: 'flex',width:"100%" }} className={"w-me"}>
            <LocaleProvider value={"ru"}>
                <DateInput
                    value={value}
                    onChange={setValue}
                    enableTime={enableTime}
                    disablePast={true}
                    disableFuture={false}
                    closeOnChange={true}
                    disablePickers={false}
                    showNeighboringMonth={true}
                    disableCalendar={false}
                />
            </LocaleProvider>
        </div>
    )
}
export default MyDateInput