import React, {useState} from "react"
import {
    Alert,Input,FormItem
} from "@vkontakte/vkui"

const Confirm = ({mess,fn,closePopout}) =>{

    return (
        <Alert
            actions={[
                {
                    title: 'Да',
                    autoClose: true,
                    action:fn
                },
                {
                    title: 'Нет',
                    autoClose: true,
                }
            ]}
            actionsLayout="horizontal"
            onClose={closePopout}
            header="Внимание"
            text={mess}
        />
    )
}
export default Confirm